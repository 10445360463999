import React from 'react';
import { SellerLevelOneBadge, SellerLevelTwoBadge, TopRatedBadge } from '@fiverr-private/badges';
import { Center } from '@fiverr-private/layout_components';
import { SellerLevel } from '../../gql-operations/gql-generated';
import { NewSellerLevelBadgeProps } from './types';

const MAP_SELLER_LEVEL_TO_BADGE = {
    [SellerLevel.LevelTrs]: TopRatedBadge,
    [SellerLevel.LevelOne]: SellerLevelOneBadge,
    [SellerLevel.LevelTwo]: SellerLevelTwoBadge,
};

export const NewSellerLevelBadge = ({ sellerLevel }: NewSellerLevelBadgeProps) => {
    if (!sellerLevel) {
        return null;
    }

    const LevelBadge = MAP_SELLER_LEVEL_TO_BADGE[sellerLevel];
    if (!LevelBadge) {
        return null;
    }

    return (
        <Center aria-label="Seller Level Badge">
            <LevelBadge />
        </Center>
    );
};
