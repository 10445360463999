import React from 'react';
import classnames from 'classnames';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import { ProfileHeader } from '../../ProfileHeader';
import { UrlContext } from '../../../utils/UrlContext';
import { ModalHeaderProps } from './types';
import classes from './index.module.scss';

export const LearnMoreModalHeader = ({ className, onRatingsClick, showSellerLevelNewBadges }: ModalHeaderProps) => {
    const { isMD } = useBreakpoints();
    const avatarSize = isMD ? 96 : 48;
    const onlineIndicatorSize = isMD ? 12 : 8;
    const isFiverrChoice = UrlContext.isFiverrChoice();

    return (
        <ProfileHeader
            className={classnames(className, 'flex')}
            avatarSize={avatarSize}
            withGeographicalData={isMD}
            isInModal
            ratingsClassName={classes.mobileHeader}
            withOneLiner={isMD}
            onlineIndicatorSize={onlineIndicatorSize}
            isFiverrChoice={isFiverrChoice}
            onRatingsClick={onRatingsClick}
            disableIntroVideo
            showSellerLevelNewBadges={showSellerLevelNewBadges}
        />
    );
};
