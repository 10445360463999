import React from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Header } from '../MoreInfoModal';
import FiverrLearnLogo from './FiverrLearn.svg';
import classes from './index.module.scss';

const T_KEY = 'seller_pages_toolbox.modal.fiverr_learn';

export const Title = () => (
    <Header
        title={
            <div className={classes.title}>
                <I18n k={`${T_KEY}.title`} />
                <div>
                    <img src={FiverrLearnLogo} className={classnames(classes.fiverrLearnLogo, 'm-l-16')} />
                </div>
            </div>
        }
    />
);
