import React, { useMemo } from 'react';
import classnames from 'classnames';
import fiverrLearnCoursesList from '@fiverr-private/fiverr_learn/src/components/CourseList/coursesList.json';
import { useSellerData } from '../../hooks/useSellerData';
import { DATETIME_ELEMENT_STYLES, useLocalizationContext } from '../../hooks/LocalizationContext';
import { Content, ContentsWrapper } from '../MoreInfoModal';
import { FederatedSeller } from '../../types';
import { Title } from './Title';
import { CustomTitle } from './CustomTitle';
import classes from './index.module.scss';

interface CoursesProps {
    customTitle?: React.ReactNode;
    contentTitleClassName?: string;
    subtitleClassName?: string;
}

export const Courses = ({ customTitle = null, contentTitleClassName, subtitleClassName }: CoursesProps) => {
    const { courses } = useSellerData(selectSellerSlice);
    const { dateTimeFormat } = useLocalizationContext();

    const transformDate = (seconds) =>
        dateTimeFormat(new Date(seconds * 1000), {
            year: DATETIME_ELEMENT_STYLES.NUMERIC,
            month: DATETIME_ELEMENT_STYLES.SHORT,
        });

    const transformedCoursesList = useMemo(
        () =>
            fiverrLearnCoursesList.coursesList.reduce(
                (acc, course) => ({
                    ...acc,
                    [course.id]: { name: course.name, img: course.img },
                }),
                {}
            ),
        []
    );

    return (
        <div className={classes.wrapper}>
            {customTitle ? <CustomTitle title={customTitle} /> : <Title />}
            <ContentsWrapper asElement="ul" ariaLabel="Courses List">
                {courses?.nodes?.map((course) => (
                    <Content
                        asElement="li"
                        subtitleClassName={classnames(classes.subtitle, subtitleClassName)}
                        titleClassName={contentTitleClassName}
                        icon={<img src={transformedCoursesList[course.id].img} className={classes.icon} />}
                        key={course.id}
                        title={transformedCoursesList[course.id].name}
                        description={transformDate(course.updatedAt)}
                    />
                ))}
            </ContentsWrapper>
        </div>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        courses: seller.courses,
    };
}
