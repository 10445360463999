import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { SellerTopRatedBadge } from '../SellerTopRatedBadge';
import { AchievementLevel } from '../../gql-operations/gql-generated';
import { SelerLevelBadgeProps } from './types';

export const SellerLevelBadge = ({ achievementLevel }: SelerLevelBadgeProps) => {
    const mapSellerLevelToText = {
        LEVEL_ONE: <I18n k="seller_pages_toolbox.seller_level" params={{ level: 1 }} />,
        LEVEL_TWO: <I18n k="seller_pages_toolbox.seller_level" params={{ level: 2 }} />,
        NO_LEVEL: null,
    };

    const sellerLevel = achievementLevel && mapSellerLevelToText[achievementLevel];

    if (achievementLevel === AchievementLevel.TopRatedSeller) {
        return <SellerTopRatedBadge />;
    }

    return (
        <Text fontWeight="semibold" size="b_sm">
            {sellerLevel}
        </Text>
    );
};
