import React, { useState } from 'react';
import ReactTruncate from 'react-truncate';
import { I18n } from '@fiverr-private/i18n-react';
import styles from './index.module.scss';
import { TruncateProps } from './types';

export const Truncate = ({
    lines = 2,
    children,
    onReadMore,
    onTruncate,
    withReadLess = false,
    initiallyTruncated = true,
}: TruncateProps) => {
    const [isTruncated, setIsTruncated] = useState(initiallyTruncated);

    const ellipsis = (
        <span
            onClick={() => {
                setIsTruncated((prev) => !prev);
                onReadMore();
            }}
        >
            {isTruncated && (
                <>
                    ...{' '}
                    <span className={styles.toggleControl}>
                        <I18n k="seller_pages_toolbox.truncate.read_more" />
                    </span>
                </>
            )}
        </span>
    );

    return (
        <div className={styles.truncateWrapper}>
            <ReactTruncate lines={isTruncated && lines} trimWhitespace ellipsis={ellipsis} onTruncate={onTruncate}>
                {children}
                {!isTruncated && ellipsis}
                {withReadLess && !isTruncated && (
                    <span
                        onClick={() => {
                            setIsTruncated((prev) => !prev);
                        }}
                        className={styles.toggleControl}
                    >
                        <I18n k="seller_pages_toolbox.truncate.read_less" />
                    </span>
                )}
            </ReactTruncate>
        </div>
    );
};
