import React, { ReactNode } from 'react';
import { Container } from '@fiverr-private/layout_components';
import { Link } from '@fiverr-private/typography';

interface Props {
    href?: string;
    onClick?: () => void;
    onMouseEnter?: () => void;
    children?: ReactNode;
}

export const ContactButtonContent = ({ href, onClick, onMouseEnter, children }: Props) =>
    href ? (
        <Link href={href} target="_blank" rel="noreferrer">
            {children}
        </Link>
    ) : (
        <Container cursor="pointer" onClick={onClick} onMouseEnter={onMouseEnter}>
            {children}
        </Container>
    );
