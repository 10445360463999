import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Rating } from '@fiverr-private/orca';
import * as colors from '@fiverr-private/sass/helpers';
import {
    Tooltip,
    EducationTooltip,
    EducationTooltipAnchor,
    EducationTooltipContent,
    EducationTooltipHeader,
    EducationTooltipBadge,
} from '@fiverr-private/tooltip';
import { Icon } from '@fiverr-private/fit';
import { PencilIcon } from '@fiverr-private/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { SellerBadge } from '@fiverr-private/listings';
import { Stack } from '@fiverr-private/layout_components';
import { useSellerData } from '../../hooks/useSellerData';
import { useIsSellerOnline } from '../../hooks/useIsSellerOnline';
import { AchievementLevel, SellerLevel } from '../../gql-operations/gql-generated';
import { formatBigNumbers } from '../../utils/formatBigNumbers';
import { FederatedSeller } from '../../types';
import { useMonitoring } from '../../utils/monitoring';
import { SellerLevelBadge } from '../SellerLevelBadge';
import { NewSellerLevelBadge } from '../NewSellerLevelBadge';
import { ProfileBadgeRatingsProps } from './types';
import styles from './index.module.scss';
import { TestimonialsBadge } from './TestimonialsBadge/TestimonialsBadge';

const DISPLAY_NAME_T_KEY = 'seller_pages_toolbox.display_name';

export const DATA_TEST_ID = 'pro-badge';

export const ProfileBadgeRatings = ({
    className,
    usernameClassName,
    source,
    isFiverrChoice,
    displayNameOnly,
    canEdit,
    withH1PublicName = false,
    showSellerLevel = false,
    showTestimonials = true,
    onDisplayNameIconClick,
    onRatingsClick,
    useTestId = false,
    showSellerLevelNewBadges = false,
}: ProfileBadgeRatingsProps) => {
    const { fireEvents, Providers } = useMonitoring();

    const {
        isPro,
        name,
        displayName,
        achievementLevel,
        sellerLevel,
        rating,
        testimonials = [],
    } = useSellerData(selectSellerSlice);

    const renderTestimonials = showTestimonials && testimonials.length > 0;

    const { score, count } = rating || {};
    const isOnline = useIsSellerOnline();

    const showOnlineIndicator = isPro && isOnline;

    const dataTestId = useTestId ? DATA_TEST_ID : undefined;

    const badgeType = useMemo(() => {
        if (isFiverrChoice) {
            return SellerBadge.BADGES.CHOICE;
        }
        if (isPro) {
            return SellerBadge.BADGES.PRO;
        }
        return undefined;
    }, [isFiverrChoice, isPro]);

    const onRatingClickBI = () => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'seller page - header - click review',
                source,
            },
        });
        onRatingsClick?.();
    };

    const { publicName, username } = displayNameOnly
        ? { publicName: displayName || name, username: '' }
        : { publicName: displayName || name, username: displayName ? `@${name}` : '' };

    const isNewTopRated = sellerLevel === SellerLevel.LevelTrs;
    const displayNewSellerLevel = showSellerLevelNewBadges && ((!!sellerLevel && showSellerLevel) || isNewTopRated);

    const isTopRated = achievementLevel === AchievementLevel.TopRatedSeller;
    const displaySellerLevel = !showSellerLevelNewBadges && ((!!achievementLevel && showSellerLevel) || isTopRated);

    const showRating = !!score && !!count;
    const showProfileRatingSection = showRating || renderTestimonials || displaySellerLevel || displayNewSellerLevel;

    const DisplayNameTag = withH1PublicName ? 'h1' : 'div';

    return (
        <>
            <div
                className={classnames(
                    className,
                    styles.profileBadgeRatings,
                    styles.profileRating,
                    'flex flex-items-center flex-start'
                )}
            >
                {showOnlineIndicator && <div className={classnames(styles.onlineCircle, 'm-r-8 flex')} />}
                <div className="flex flex-items-center flex-start p-r-16">
                    <DisplayNameTag
                        aria-label="Public Name"
                        className={classnames(usernameClassName, 'tbody-2 text-bold co-text-darkest')}
                    >
                        {publicName}
                    </DisplayNameTag>
                    {username && (
                        <div
                            aria-label="Username"
                            className={classnames(styles.username, 'm-l-8 tbody-4 co-text-medium')}
                        >
                            {username}
                        </div>
                    )}
                    {canEdit && (
                        <div className="m-l-12">
                            <EducationTooltip tooltipStorageKey="display_name_education">
                                <EducationTooltipAnchor>
                                    <div>
                                        <Tooltip
                                            content={
                                                <I18n k="seller_pages_toolbox.display_name.display_name_tooltip" />
                                            }
                                        >
                                            <Icon onClick={onDisplayNameIconClick}>
                                                <PencilIcon color={colors.grey_900} />
                                            </Icon>
                                        </Tooltip>
                                    </div>
                                </EducationTooltipAnchor>
                                <EducationTooltipContent>
                                    <EducationTooltipHeader>
                                        <I18n k={`${DISPLAY_NAME_T_KEY}.education_title`} />
                                        <EducationTooltipBadge />
                                    </EducationTooltipHeader>
                                    <I18n k={`${DISPLAY_NAME_T_KEY}.education_body`} />
                                </EducationTooltipContent>
                            </EducationTooltip>
                        </div>
                    )}
                    {badgeType && (
                        <span aria-label="Seller Badge">
                            <SellerBadge className="m-l-8" badge={badgeType} dataTestId={dataTestId} />
                        </span>
                    )}
                </div>
            </div>
            {showProfileRatingSection && (
                <Stack
                    gap="3"
                    alignItems="stretch"
                    className={classnames(styles.profileBadgeRatings, styles.profileRating)}
                    withDivider
                >
                    {showRating && (
                        <span aria-label="Rating">
                            <Rating
                                className={classnames(styles.rating)}
                                starClassName={styles.star}
                                scoreClassName={styles.score}
                                theme={(Rating as any).THEMES.ONE_STAR}
                                scoreMinimumFractionDigits={1}
                                scoreMaximumFractionDigits={1}
                                ratingsCountClassName={styles.counts}
                                color={(Rating as any).COLORS.BLACK}
                                score={score}
                                ratingsCount={formatBigNumbers(count || 0)}
                                onRatingsClick={onRatingClickBI}
                            />
                        </span>
                    )}
                    {renderTestimonials && <TestimonialsBadge testimonialsCount={testimonials.length} />}
                    {displaySellerLevel && <SellerLevelBadge achievementLevel={achievementLevel} />}
                    {displayNewSellerLevel && <NewSellerLevelBadge sellerLevel={sellerLevel} />}
                </Stack>
            )}
        </>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        rating: seller.rating,
        achievementLevel: seller.achievementLevel,
        isPro: seller.isPro,
        displayName: seller.user.profile.displayName,
        name: seller.user.name,
        testimonials: seller.testimonials,
        sellerLevel: seller.sellerLevel,
    };
}
