import React from 'react';
import { Button } from '@fiverr-private/button';
import Collect from '@fiverr-private/collect';
import { RESOURCE_TYPES } from '@fiverr-private/collect/src/utils/constants';
import { HeartIcon, HeartSolidIcon } from '@fiverr-private/icons';
import { colorRed700 } from '@fiverr-private/sass/helpers/index';
import { Icon, Popover } from '@fiverr-private/fit';
import { SellerPageFederatedSeller, useSellerData } from '@fiverr-private/seller_pages_toolbox';
import { useCurrentUserCollections } from '../../hooks/useCollections';
import { SaveSellerButtonProps } from './types';
import classes from './index.module.scss';

export const SaveSellerButton = ({ className }: SaveSellerButtonProps) => {
    const { sellerId } = useSellerData(selectSellerSlice);
    const [collections, setCollections] = useCurrentUserCollections();

    const isSellerSavedInCollection =
        collections.length > 0 &&
        collections.some(({ items }) =>
            items?.some(
                ({ resourceType, resourceId }) => resourceType === RESOURCE_TYPES.SELLER && resourceId === `${sellerId}`
            )
        );

    const heartIcon = isSellerSavedInCollection ? (
        <Icon color={colorRed700}>{HeartSolidIcon}</Icon>
    ) : (
        <Icon>{HeartIcon}</Icon>
    );

    const HamburgerIcon = () => (
        <Button className={classes.saveSellerBtn} variant="outline" intent="secondary">
            {heartIcon}
        </Button>
    );

    return (
        <div className={className}>
            <Collect
                collections={collections}
                showMenu
                allowMenuOverflow
                onUpdateCollections={(collections) => setCollections(collections)}
                menuPosition={(Popover as any).POSITIONS.BOTTOM_RIGHT}
                heartIcon={null}
                hamburgerIcon={<HamburgerIcon />}
                resource={{
                    id: sellerId,
                    type: RESOURCE_TYPES.SELLER,
                }}
            />
        </div>
    );
};

function selectSellerSlice(seller: SellerPageFederatedSeller) {
    return {
        sellerId: seller.user.id,
    };
}
