import { getContext } from '@fiverr-private/fiverr_context';
import { tryInvoke } from '../tryInvoke';
import { parseActiveArrayFilters, buildActiveFilters } from './urlActiveFilters';

export interface UrlSkill {
    id: string | number;
    parentId: string | number;
    type: 'leaf_cat_id' | 'skill' | string;
}

export const UrlContext = {
    parseQueryParams: (url?: string) => {
        const { queryParameters = {} } = getContext();
        const params = url ? tryInvoke(() => Object.fromEntries(new URL(url).searchParams), {}) : queryParameters;

        return params;
    },

    parseActiveArrayFilters: (values = '') => tryInvoke(() => parseActiveArrayFilters(values), []),

    buildActiveFilters: (filters = {}) => tryInvoke(() => buildActiveFilters(filters), ''),

    isFiverrChoice: () => {
        const isChoice = UrlContext.parseQueryParams().is_choice;
        return isChoice && isChoice !== 'false';
    },

    getUrlSkills: (): UrlSkill[] => {
        const { expertises } = UrlContext.parseQueryParams();
        return tryInvoke(() => UrlContext.parseActiveArrayFilters(expertises), []);
    },

    getUrlGigs: () => {
        const { gigs } = UrlContext.parseQueryParams();
        return tryInvoke(() => UrlContext.parseActiveArrayFilters(gigs), []);
    },

    getRefGigSlug: () => {
        const { referrer_gig_slug } = UrlContext.parseQueryParams();
        return referrer_gig_slug ? { slug: referrer_gig_slug } : undefined;
    },
};
