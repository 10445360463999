import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import {
    SellerPageFederatedSeller,
    LazyComponent,
    raiseError,
    useContactDataClick,
    useCurrentPageName,
    useSellerData,
} from '@fiverr-private/seller_pages_toolbox';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import { getContext } from '@fiverr-private/fiverr_context';
import { useRoleVariation } from '../../hooks/useRoleVariation';
import { useSelectedRole } from '../../hooks/useSelectedRole';
import { getRoleTitleTextualEntity } from '../../utils/getRoleTitleTextualEntity';
import { useContactSellerModal, useContactSellerModalUpdater } from './hooks/useContactSellerModal';

const lazyImportModal = () => import(/* webpackChunkName: 'ContactSellerModal' */ './modal');

const ContactSellerModal = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { userId } = getContext();
    const isLoggedIn = !!userId;
    const setShowModal = useContactSellerModalUpdater();
    const currentRole = useSelectedRole();
    const roleVariation = useRoleVariation();

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const { sellerId, username, displayName, isActivationCompleted } = useSellerData(selectSellerSlice);

    const { isMD } = useBreakpoints();

    const currentPageName = useCurrentPageName();

    const { modalInitialData, refetchInitialData } = useContactDataClick({
        username,
        isVisible,
        isLoggedIn,
        isMD,
        userHasPendingState: !isActivationCompleted,
        onError: () => raiseError(),
    });

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    const modalProps = useMemo(() => {
        const recipientInfo = modalInitialData?.recipient || {};
        const show = !isEmpty(modalInitialData);
        const professionRelatedTranslationKey = currentRole
            ? getRoleTitleTextualEntity(currentRole, roleVariation[currentRole.id])?.translationKey
            : undefined;

        return {
            pageName: currentPageName,
            professionRelatedTranslationKey,
            isOpen: show,
            recipientInfo: {
                ...recipientInfo,
                sellerId,
                displayName,
            },
            attachmentsUploadConfig: modalInitialData?.attachmentsUploadConfig,
            showInfo: true,
            source: 'business_page_contact_me',
            onSubmitSuccess: refetchInitialData,
            onClose: handleCloseModal,
        };
    }, [
        currentPageName,
        refetchInitialData,
        modalInitialData,
        handleCloseModal,
        sellerId,
        displayName,
        currentRole,
        roleVariation,
    ]);

    return (
        <LazyComponent
            moduleName="ContactSellerModal"
            lazyImport={lazyImportModal}
            shouldImport
            componentProps={modalProps}
        />
    );
};

export const ContactSellerModalWrapper = () => {
    const showModal = useContactSellerModal();

    if (!showModal) {
        return null;
    }

    return <ContactSellerModal />;
};

function selectSellerSlice(seller: SellerPageFederatedSeller) {
    return {
        sellerId: seller.user.id,
        username: seller.user.name,
        displayName: seller.user.profile.displayName,
        isActivationCompleted: seller.user.isActivationCompleted,
    };
}
