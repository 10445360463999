import React, { useEffect, useState } from 'react';
import { I18n } from '@fiverr-private/i18n-react';

const getCurrentTime = ({ timeZone }: { timeZone: string }) =>
    new Date().toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone,
    });

export const LocalTimeInfo = ({ timeZone }: { timeZone: string }) => {
    const [localTime, setLocalTime] = useState('');

    useEffect(() => {
        if (!timeZone) {
            return;
        }

        if (!localTime) {
            setLocalTime(getCurrentTime({ timeZone }));
        }

        const timeout = (60 - new Date().getSeconds()) * 1000;
        const timer = setTimeout(() => {
            setLocalTime(getCurrentTime({ timeZone }));
        }, timeout);
        return () => clearTimeout(timer);
    }, [timeZone, localTime]);

    if (!localTime) {
        return null;
    }

    return (
        <div>
            {localTime} <I18n k="seller_pages_toolbox.local_time_info.title" />
        </div>
    );
};
