import { useEffect } from 'react';
import { useMonitoring, usePortfolio } from '@fiverr-private/seller_pages_toolbox';

export const usePortfolioEventsReporter = () => {
    const { hasPortfolio } = usePortfolio();
    const { fireEvents, Providers } = useMonitoring();

    useEffect(() => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'Viewed page',
                'Has portfolio': hasPortfolio,
            },
        });

        if (hasPortfolio) {
            fireEvents({
                [Providers.Mixpanel]: {
                    type: 'Seller portfolio exists',
                },
            });
        }
    }, [hasPortfolio, Providers.Mixpanel, fireEvents]);
};
