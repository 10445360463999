import React from 'react';
import classnames from 'classnames';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import { ContactButton, isBrowser, RespondTimeInfo } from '@fiverr-private/seller_pages_toolbox';
import { useContactSellerModalUpdater } from '../ContactSellerModal/hooks/useContactSellerModal';
import { StickyFooterProps } from './types';
import styles from './index.module.scss';

const stickyFooterClasses = 'p-16 flex flex-items-center flex-end box-shadow-z2 flex-center flex-col-reverse';

export const StickyFooter = ({ className }: StickyFooterProps) => {
    const { isMD } = useBreakpoints();

    const setShowModal = useContactSellerModalUpdater();

    const onContactOpen = () => {
        setShowModal(true);
    };

    if (isMD || !isBrowser()) {
        return null;
    }

    return (
        <div className={classnames(className, styles.stickyFooter, stickyFooterClasses)}>
            <RespondTimeInfo />
            <ContactButton onContactOpen={onContactOpen} className={styles.contactBtn} />
        </div>
    );
};
