import { atom, Getter, Setter, useAtom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { api as collectApi } from '@fiverr-private/collect';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { Collection } from '../types';

const collectionsAtom = atom<Collection[]>([]);

const fetchCollectionsEffect = atomEffect((_get: Getter, set: Setter) => {
    const { userId } = getContext();

    if (!userId) {
        return;
    }

    collectApi
        .getCollections()
        .then(({ collections }) => {
            set(collectionsAtom, collections);
        })
        .catch((originalError) => {
            const err = new Error(`Failed to fetch collections for userId: ${userId}`);
            logger.error(err, {
                cause: originalError,
            });
        });
});

export const useCurrentUserCollections = () => {
    useAtom(fetchCollectionsEffect);
    return useAtom(collectionsAtom);
};
