import React, { useCallback } from 'react';
import { CurrencyConverter } from '@fiverr-private/futile';
import { currencyFormat, dateTimeFormat, numberFormat } from '@fiverr-private/localization';
import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_CURRENCY } from '../../constants';
import { getInUsd } from './utils';

export { DATETIME_ELEMENT_STYLES, NUMBER_STYLES } from '@fiverr-private/localization';

export const LocalizationContext = React.createContext({
    currency: DEFAULT_CURRENCY,
    currencyFormatFromUSD: getInUsd,
    forceRoundCurrencyFormatFromUSD: getInUsd,
    dateTimeFormat: (value, options = {}) => dateTimeFormat({ options, value }),
    numberFormat: (value, options = {}) => numberFormat({ options, value }),
});

export const useLocalizationContext = () => React.useContext(LocalizationContext);

export const LocalizationContextProvider = ({ currency, children }: any) => {
    const { locale } = getContext();

    const currencyFormatFromUSD = useCallback(
        (priceInUsd) => {
            let convertedPrice = priceInUsd * currency.rate;
            if (currency.forceRound && convertedPrice >= currency.forceRoundFromAmount) {
                convertedPrice = Math.ceil(convertedPrice);
            }
            return currencyFormat({
                value: convertedPrice,
                currencyCode: currency.name,
            });
        },
        [currency]
    );

    /**
     * Returns formatted currency according to the user selected currency, and always rounded.
     */
    const forceRoundCurrencyFormatFromUSD = useCallback(
        (priceInUsd) => {
            const currencyObj = { ...currency, forceRound: true, forceRoundFromAmount: 0 };
            const convertedPrice = priceInUsd * currencyObj.rate;

            const currencyConverter = new CurrencyConverter(currencyObj);

            const fallbackValue = currencyConverter.convert(priceInUsd, {
                commaSeparate: true,
            });

            return currencyFormat({
                value: convertedPrice,
                currencyCode: currencyObj.name,
                options: {
                    fallbackValue,
                    noFractionDigits: currencyObj.forceRound,
                },
            });
        },
        [currency]
    );

    const dateTimeFormatInternal = useCallback(
        (date, options = {}) => {
            const value = date instanceof Date ? date : new Date(date);
            return dateTimeFormat({ value, options: { ...options, formattingLocale: locale } });
        },
        [locale]
    );

    const numberFormatInternal = useCallback(
        (value, options = {}) => numberFormat({ value, options: { ...options, formattingLocale: locale } }),
        [locale]
    );

    return (
        <LocalizationContext.Provider
            value={{
                currency,
                currencyFormatFromUSD,
                forceRoundCurrencyFormatFromUSD,
                dateTimeFormat: dateTimeFormatInternal,
                numberFormat: numberFormatInternal,
            }}
        >
            {children}
        </LocalizationContext.Provider>
    );
};
