import React from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { logger } from '@fiverr-private/obs';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { useMonitoring } from '../../utils/monitoring';
import { Truncate } from '../Truncate';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import styles from './index.module.scss';
import { AboutMeProps } from './types';

const T_PREFIX = 'seller_pages_toolbox.about';

const onRenderError = (originalError) => {
    const err = new Error(`Failed while rendering the AboutMe Component`);
    logger.error(err, {
        cause: originalError,
    });
};

export const AboutMe = ({ className, withClamp = true }: AboutMeProps) => {
    const { Providers, fireEvents } = useMonitoring();
    const { description } = useSellerData(selectSellerSlice);

    const onReadMoreBI = () => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'seller page - description - click read more',
            },
        });
    };

    return (
        <ErrorBoundary onError={onRenderError}>
            <div className={classnames(className, styles.aboutMe)}>
                <div className={classnames(styles.title, 'm-b-8 text-semi-bold co-text-darkest')}>
                    <I18n k={`${T_PREFIX}.title`} />
                </div>
                <div className={classnames(styles.description)}>
                    {withClamp ? (
                        <Truncate lines={3} onReadMore={onReadMoreBI}>
                            {description}
                        </Truncate>
                    ) : (
                        description
                    )}
                </div>
            </div>
        </ErrorBoundary>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        description: seller.description,
    };
}
