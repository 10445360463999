import axios from 'axios';
import pathfinder from '@fiverr-private/pathfinder';

const getUrlPath = () => pathfinder('user_profile_edit');

interface SetDisplayNameAndOneLinerPayload {
    displayName: string;
    locale: string;
    oneLiner: string;
}

export const setDisplayNameAndOneLiner = ({ displayName, locale, oneLiner }: SetDisplayNameAndOneLinerPayload) =>
    axios.put(getUrlPath(), { display_name: displayName, locale, one_liner: oneLiner }).then(({ data }) => data);
