import React from 'react';
import classNames from 'classnames';
import { BusinessOutlineIcon, VettedIcon } from '@fiverr-private/icons';
import { business_800 } from '@fiverr-private/sass/helpers';
import { translate } from '@fiverr-private/i18n-react';
import { PropositionPointsProps } from './types';
import styles from './styles.module.scss';

const COPY_PREFIX = 'seller_pages_toolbox.pro_overview.proposition_points';

export const PropositionPoints = ({ className, itemClassName }: PropositionPointsProps) => {
    const points = [
        {
            IconComponent: VettedIcon,
            copyKey: `${COPY_PREFIX}.fiverr_selected`,
        },
        {
            IconComponent: BusinessOutlineIcon,
            copyKey: `${COPY_PREFIX}.experience`,
        },
    ];

    return (
        <ul className={classNames('flex', styles.list, className)}>
            {points.map(({ copyKey, IconComponent }, index) => (
                <li
                    key={`proposition-point-${index}`}
                    className={classNames('flex', 'flex-items-baseline', styles.item, itemClassName)}
                >
                    <span className={classNames('flex-center', 'm-r-8', styles.iconContainer)}>
                        <IconComponent size={20} color={business_800} />
                    </span>
                    <p className={styles.label}>{translate(copyKey)}</p>
                </li>
            ))}
        </ul>
    );
};
