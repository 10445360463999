import React from 'react';
import { logger } from '@fiverr-private/obs';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { LazyComponent } from '../LazyComponent/LazyComponent';

const lazyImport = () => import(/* webpackChunkName: 'WorkExperience' */ './WorkExperienceResolver');

const onRenderError = (originalError) => {
    const err = new Error('Failed while rendering the LazyWorkExperience Component');
    logger.error(err, {
        cause: originalError,
    });
};

export const WorkExperienceSection = (props) => (
    <ErrorBoundary onError={onRenderError}>
        <LazyComponent moduleName="WorkExperience" lazyImport={lazyImport} componentProps={props} />
    </ErrorBoundary>
);
