import axios from 'axios';
import pathfinder from '@fiverr-private/pathfinder';
import { logger } from '@fiverr-private/obs';
import { mapKeysToCamelCase } from '../../utils/mapPropsToCamelCase';

export const getIsUserOnline = async (username: string) => {
    const isOnlineUrl = pathfinder('is_online', { username });

    try {
        const resp = await axios.get(isOnlineUrl);

        return mapKeysToCamelCase(resp.data);
    } catch (originalError: any) {
        const err = new Error(`Failed to get is_online state for username: ${username}`);

        logger.error(err, {
            username,
            cause: originalError,
        });

        return { isOnline: false };
    }
};
