import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { PinIcon } from '@fiverr-private/icons';
import { Stack } from '@fiverr-private/layout_components';
import { theme } from '@fiverr-private/theme';
import { Text } from '@fiverr-private/typography';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';

interface LocationProps {
    textSize?: keyof typeof theme.bodySizes;
}

export const Location = ({ textSize = theme.bodySizes.b_md }: LocationProps) => {
    const { address } = useSellerData(selectSellerSlice);

    if (!address) {
        return null;
    }

    const { countryCode } = address;

    return (
        <Stack gap="1" alignItems="center">
            <PinIcon size={16} color={theme.colors.grey_1200} />
            <Text color="bodyPrimary" as="span" size={textSize}>
                <I18n k={`countries.${countryCode}`} />
            </Text>
        </Stack>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        address: seller.user.address,
    };
}
