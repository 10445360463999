import React from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { TraderDetailsModalTrigger } from '@fiverr-private/trader_details';
import { TraderMoreInfoTriggerProps } from './types';
import classes from './index.module.scss';

export const TraderMoreInfoTrigger = ({ className, sellerId, sellerName }: TraderMoreInfoTriggerProps) => (
    <TraderDetailsModalTrigger sellerId={sellerId} sellerName={sellerName}>
        <div className={classnames(classes.trigger, className)}>
            <I18n k="seller_page_perseus.trader_info.more_info" />
        </div>
    </TraderDetailsModalTrigger>
);
