import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { LocalTimeInfo } from '../LocalTime/LocalTime';
import { useLocalizationData } from '../../hooks/useLocalizationData';

interface UserInfoContainerProps {
    isOnline: boolean;
}

export const UserInfoContainer = ({ isOnline }: UserInfoContainerProps) => {
    const localizationData = useLocalizationData();
    const timeZone = localizationData?.timezone;

    const isOnlineTitle = isOnline ? (
        <I18n k="seller_pages_toolbox.local_time_info.online" />
    ) : (
        <I18n k="seller_pages_toolbox.local_time_info.offline" />
    );

    return (
        <>
            <div className="flex co-text-dark m-b-4">
                {isOnlineTitle}
                {timeZone && (
                    <>
                        &nbsp;•&nbsp; <LocalTimeInfo timeZone={timeZone} />
                    </>
                )}
            </div>
        </>
    );
};
