import {
    snakeCase,
    camelCase,
    startCase,
    isPlainObject,
    isArray,
    mapKeys,
    map,
    mapValues,
    cloneDeep,
    flow,
} from 'lodash';

export const Serializers = {
    SnakeCase: snakeCase,
    CamelCase: camelCase,
    StartCase: startCase,
};

const serialize = (source: any, serializer = Serializers.SnakeCase): any => {
    if (!isPlainObject(source)) {
        return source;
    }

    const serializeKeys = (obj: object) => mapKeys(obj, (_, key) => serializer(key));

    const serializedSource = flow([cloneDeep, serializeKeys])(source);

    return mapValues(serializedSource, (value) => {
        if (isPlainObject(value)) {
            return serialize(value, serializer);
        } else if (isArray(value)) {
            return map(value, (v) => serialize(v, serializer));
        } else {
            return value;
        }
    });
};

export default serialize;
