import React from 'react';
import { debounce } from 'lodash';
import { StatusToast } from '@fiverr-private/orca';
import { I18n } from '@fiverr-private/i18n-react';

export const raiseError = debounce((errorMessage?: string | React.ReactNode) =>
    StatusToast.raise({
        message: errorMessage || <I18n k="seller_pages_toolbox.toast.error" />,
        theme: (StatusToast as any).THEMES.ERROR,
    })
);
