import { SellerRole } from '../../gql-operations/gql-generated';

export const getRoleTitleTextualEntity = (role: SellerRole, variantId?: string) => {
    if (variantId) {
        const variation = role.variations.find((variation) => variation.id === variantId);
        if (variation) {
            return variation.name;
        }
    }

    return role.role.name;
};
