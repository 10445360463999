export const formatBigNumbers = (number: number) => {
    if (isNaN(number) || number < 1000) {
        return `${number}`;
    }

    const units = ['', 'k', 'M', 'B', 'T'];

    const magnitude = Math.floor(Math.log10(Math.abs(number)) / 3);
    const suffix = units[Math.min(magnitude, units.length - 1)];
    const formattedNumber = (number / Math.pow(10, magnitude * 3)).toFixed(1);

    return formattedNumber + suffix;
};
