import { getContext } from '@fiverr-private/fiverr_context';
import { SellerPageFederatedSeller, useIsSelfView, useSellerData } from '@fiverr-private/seller_pages_toolbox';

export const useShouldRenderSaveSellerButton = () => {
    const { userId } = getContext();
    const { IsSeller } = useSellerData(selectSellerSlice);
    const isSelfView = useIsSelfView();

    return userId && IsSeller && !isSelfView;
};

function selectSellerSlice(seller: SellerPageFederatedSeller) {
    return {
        IsSeller: seller.approvedGigsCount > 0,
    };
}
