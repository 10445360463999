import { isEmpty, omitBy, fromPairs } from 'lodash';

const URL_FILTER_SEPARATOR = {
    PARAMS: '|',
    PARAM_VALUE: ':',
    ARRAY_VALUE: ',',
    PAIR_VALUE: '-',
};

/**
 * Read contextual string value, and convert to array
 * Example1 {values = "id:163618088,pckg_id:1|id:199847706,pckg_id:2"}
 * Result1 { [{ "id": "163618088", "pckg_id": "1" }, { "id": "199847706", "pckg_id": "2" }] }
 * Example2 {values = "slug:slug-1,pckg_id:1|slug:slug-2,pckg_id:2"}
 * Result2 { [{ "slug": "slug-1", "pckg_id": "1" }, { "slug": "slug-2", "pckg_id": "2" }] }
 * @param {string} values
 * @returns {Array<Record<string, string>>}
 */
export const parseActiveArrayFilters = (values = '') =>
    values
        .split(URL_FILTER_SEPARATOR.PARAMS)
        .filter((v) => !!v)
        .map((item) => {
            // Read "id:163618088,pckg_id:1" and convert to object
            const pairs = item
                .split(URL_FILTER_SEPARATOR.ARRAY_VALUE)
                .map((pair) => pair.split(URL_FILTER_SEPARATOR.PARAM_VALUE));
            return omitBy(fromPairs(pairs), (_, key) => !key);
        })
        .filter((item) => !isEmpty(item));

/**
 * @param {string=} values
 * @returns {import('./types').RefFilters}
 */
export const parseActiveRefFilters = (values = '') =>
    values
        .split(URL_FILTER_SEPARATOR.PARAMS)
        .map((item) => {
            const [key, value] = item.split(URL_FILTER_SEPARATOR.PARAM_VALUE);
            return { [key]: value };
        })
        .reduce((acc, record) => ({ ...acc, ...record }));

const stringifyActiveFilterValue = (value: any) =>
    Array.isArray(value) ? value.join(URL_FILTER_SEPARATOR.ARRAY_VALUE) : value;

export const buildActiveFilters = (activeFilters: any = {}): string => {
    if (Array.isArray(activeFilters)) {
        return activeFilters.map(buildActiveFilters).join(URL_FILTER_SEPARATOR.PARAMS);
    }

    return Object.entries(activeFilters)
        .map(([key, value]) => {
            const pair = [key, stringifyActiveFilterValue(value)].join(URL_FILTER_SEPARATOR.PARAM_VALUE);
            return pair;
        })
        .join(URL_FILTER_SEPARATOR.ARRAY_VALUE);
};
