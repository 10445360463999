export const ALLOCATIONS = {
    CONTROL: '1',
    TEST_B: '2',
    TEST_C: '3',
    TEST_D: '4',
};

export const DEFAULT_CURRENCY = {
    name: 'USD',
    rate: 1,
    // eslint-disable-next-line no-template-curly-in-string
    template: '${{amount}}',
};

export const EXPERTISE_TYPE = {
    SKILL: 'skill',
    LEAF_CATEGORY_ID: 'leaf_cat_id',
};

export enum SUPPORTED_ACTIONS {
    GET_A_QUOTE = 'get_a_quote',
    CONTACT_SELLER = 'contact_seller',
}
