import React from 'react';
import classnames from 'classnames';
import { Stack } from '@fiverr-private/layout_components';
import FiverrLearnLogo from './FiverrLearn.svg';
import classes from './index.module.scss';

interface CustomTitleProps {
    title: React.ReactNode;
}

export const CustomTitle = ({ title }: CustomTitleProps) => (
    <Stack justifyContent="spaceBetween" marginBottom="3">
        {title}
        <div>
            <img src={FiverrLearnLogo} className={classnames(classes.fiverrLearnLogo, 'm-l-16')} />
        </div>
    </Stack>
);
