import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { SUPPORTED_ACTIONS } from '../../constants';
import { useCurrentPageName } from '../../hooks/useCurrentPageName';
import UserActivationModalLazy from './UserActivationModalWrapperLazy';

const BASE_KEY = 'seller_pages_toolbox.user_activation_modal';

interface UserActivationModalProps {
    isOpen: boolean;
    onClose: () => void;
    action: SUPPORTED_ACTIONS;
}

export const UserActivationModalWrapper = ({
    isOpen,
    onClose,
    action = SUPPORTED_ACTIONS.CONTACT_SELLER,
}: UserActivationModalProps) => {
    const currentPageName = useCurrentPageName();
    const { pathParameters, userId } = getContext();
    const isLoggedIn = !!userId;
    const { username: sellerUsername } = pathParameters || { username: '' };
    const description = <I18n k={`${BASE_KEY}.${action}.description`} params={{ sellerUsername }} />;

    return (
        <>
            {isLoggedIn && (
                <UserActivationModalLazy
                    isOpen={isOpen}
                    onClose={onClose}
                    pageName={currentPageName}
                    description={description}
                />
            )}
        </>
    );
};
