import { logger } from '@fiverr-private/obs';
import { Maybe } from '../gql-operations/gql-generated';

const otherSubCategoryKeys = [9, 20, 31, 38, 97, 117, 118, 120, 121, 122, 123, 137, 240, 457];

interface GetCategoryKeyArgs {
    categoryId?: number;
    subCategoryId: number;
    nestedCategoryId?: Maybe<number>;
    replaceOther?: boolean;
}

export const getCategoryKey = ({
    categoryId,
    subCategoryId,
    nestedCategoryId,
    replaceOther = true,
}: GetCategoryKeyArgs) => {
    const categoryKey = `categories.category_${categoryId}`;
    const categoryKeyName = categoryId ? `${categoryKey}.name` : undefined;
    const subCategoryKey = `sub_categories.sub_category_${subCategoryId}`;
    const nestedSubCategoryName = `${subCategoryKey}.nested_sub_cat_${nestedCategoryId}.name`;
    if (subCategoryId && nestedCategoryId) {
        return nestedSubCategoryName;
    }
    if (subCategoryId) {
        let subCategoryKeyName: string | undefined = `${subCategoryKey}.name`;

        if (replaceOther && otherSubCategoryKeys.includes(Number(subCategoryId))) {
            subCategoryKeyName = categoryId ? categoryKeyName : subCategoryKeyName;

            if (!categoryId) {
                logger.warn({
                    message: '"categoryId" not defined for replace "Other" sub-category title',
                    details: { categoryId, subCategoryId, nestedCategoryId },
                });
            }
        }

        return subCategoryKeyName;
    }
    return categoryKeyName;
};
