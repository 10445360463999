import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { activeConsultationPackages } from '@fiverr-private/consultation';
import { I18n } from '@fiverr-private/i18n-react';
import { Avatar } from '@fiverr-private/fit';
import { logger } from '@fiverr-private/obs';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { HourlyIcon } from '@fiverr-private/visuals';
import { ConsultationButton } from '../ConsultationButton';
import { useSellerData } from '../../hooks/useSellerData';
import { useSellerVacation } from '../../hooks/useSellerVacation';
import { useConsultationData } from '../../hooks/useConsultationData';
import { UserInfoContainer } from '../UserInfoContainer';
import { RespondTimeInfo } from '../RespondTimeInfo';
import { useCurrentPageName } from '../../hooks/useCurrentPageName';
import { ContactButton } from '../ActionButtons/ContactButton';
import { useIsSellerOnline } from '../../hooks/useIsSellerOnline';
import { FederatedSeller } from '../../types';
import styles from './index.module.scss';
import { ContactCardProps } from './types';

const T_PREFIX = 'seller_pages_toolbox.contact';

const onRenderError = (originalError) => {
    const err = new Error(`Failed while rendering the ContactCard Component`);
    logger.error(err, {
        cause: originalError,
    });
};

export const ContactCard: React.FC<ContactCardProps> = ({
    className,
    withTitle = true,
    newView,
    onContactOpen,
    triggerId,
    triggerCopy,
    triggerPlacement,
}) => {
    const { isOnVacation } = useSellerVacation();
    const {
        displayName: sellerDisplayName,
        sellerName,
        profileImageUrl,
        hourlyRate,
    } = useSellerData(selectSellerSlice);
    const consultationData = useConsultationData();
    const pageName = useCurrentPageName();
    const isOnline = useIsSellerOnline();

    const hasConsultation = !isEmpty(consultationData) && !!activeConsultationPackages(consultationData.pricing).length;

    const displayName = sellerDisplayName || sellerName;

    return (
        <ErrorBoundary onError={onRenderError}>
            <div className={classnames(className, styles.contactCard, 'p-32 box-shadow-z1')}>
                {newView && (
                    <div className={styles.avatarBox}>
                        <div className="m-r-16">
                            <Avatar
                                size={48}
                                username={sellerName}
                                src={profileImageUrl}
                                isOnline={isOnline}
                                showOnlineIndicator={isOnline}
                                onlineIndicatorSize={8}
                                onlineIndicatorPosition={(Avatar as any).ONLINE_INDICATOR_POSITIONS.BOTTOM_RIGHT}
                            />
                        </div>
                        <div>
                            <div className="text-display-6 m-b-8">{displayName}</div>
                            <UserInfoContainer isOnline={isOnline} />
                            {hourlyRate && (
                                <div className="flex flex-items-center" style={{ gap: 8 }}>
                                    <HourlyIcon />
                                    <I18n k={`${T_PREFIX}.offers_hourly_rates`} />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!newView && withTitle && !isOnVacation && (
                    <div className="flex flex-center co-text-darker text-semi-bold">
                        <I18n k={`${T_PREFIX}.title`} />
                    </div>
                )}

                <ContactButton
                    onContactOpen={onContactOpen}
                    className={classnames(styles.contactButton, 'm-t-20 m-b-16')}
                    size="lg"
                    source={pageName}
                    triggerId={triggerId}
                    triggerCopy={triggerCopy}
                    triggerPlacement={triggerPlacement}
                />

                {hasConsultation && <ConsultationButton className="m-b-16" />}

                <RespondTimeInfo />
            </div>
        </ErrorBoundary>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        displayName: seller.user.profile.displayName,
        sellerName: seller.user.name,
        hourlyRate: seller.hourlyRate,
        profileImageUrl: seller.user.profileImageUrl,
    };
}
