import { useEffect, useState } from 'react';
import { useAtom } from 'jotai/index';
import { getContext } from '@fiverr-private/fiverr_context';
import pathfinder from '@fiverr-private/pathfinder';
import { axiosWrapper, SellerPageFederatedSeller, useSellerData } from '@fiverr-private/seller_pages_toolbox';
import { logger } from '@fiverr-private/obs';
import { ROLLOUTS } from '../../server/middlewares/addRolloutsData/constants';
import { rolloutsAtom } from '../atoms';

export const useShouldRenderTraderDetailsButton = () => {
    const { userId } = getContext();
    const { sellerId } = useSellerData(selectSellerSlice);
    const [isEligible, setIsEligible] = useState(false);
    const [rollouts] = useAtom(rolloutsAtom);
    const isInGrizzliesTraderDetailsDisplayingRollout = rollouts[ROLLOUTS.GRIZZLIES_TRADER_DETAILS_DISPLAYING];

    useEffect(() => {
        const getTraderDetailsEligibilityStatus = async () => {
            const url = pathfinder('verifications_get_trader_details_eligibility', { id: sellerId });

            try {
                const { data } = await axiosWrapper.get(url, { timeout: 700 }, 'verification_pegasus');

                setIsEligible(data?.eligibility === 'eligible');
            } catch (originalError: any) {
                const err = new Error(
                    `Failed to trader details eligibility for user ID : ${userId} and Seller ID: ${sellerId}`
                );
                logger.error(err, {
                    cause: originalError,
                });
            }
        };

        if (userId && isInGrizzliesTraderDetailsDisplayingRollout) {
            getTraderDetailsEligibilityStatus();
        }
    }, [sellerId, userId, isInGrizzliesTraderDetailsDisplayingRollout]);

    return isEligible;
};

function selectSellerSlice(seller: SellerPageFederatedSeller) {
    return {
        sellerId: seller.user.id,
    };
}
