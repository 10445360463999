import { useState, useEffect, useMemo } from 'react';

/**
 * README: Handling different client and server content
 * https://beta.reactjs.org/reference/react-dom/hydrate#handling-different-client-and-server-content
 */
export const useHydrationState = (componentId = '') => {
    const [isClient, setIsClient] = useState(false);

    const componentKey = useMemo(() => `${componentId}_${String(isClient)}`, [isClient, componentId]);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return {
        isClient,
        componentKey,
    };
};
