import { EXPERTISE_TYPE } from '../constants';
import { UrlSkill } from './UrlContext/UrlContext';
import { getCategoryKey } from './getCategoryKey';

export const getSkillKey = ({ id }) => `structured_skills.${id}.name`;
export const getSkillAlias = ({ id }) => `structured_skills.${id}.profession_alias`;

export const getExpertiseKey = ({ type, id, parentId }: UrlSkill) => {
    switch (type) {
        case EXPERTISE_TYPE.SKILL:
            return getSkillKey({ id });
        case EXPERTISE_TYPE.LEAF_CATEGORY_ID:
            return parentId === id
                ? getCategoryKey({ subCategoryId: id as number })
                : getCategoryKey({ subCategoryId: parentId as number, nestedCategoryId: id as number });
        default:
            return null;
    }
};
