import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { values } from 'lodash';
import { useAtom } from 'jotai';
import { Section, Grid, Col, Avatar } from '@fiverr-private/fit';
import { TabGroup, Tab } from '@fiverr-private/orca';
import { I18n } from '@fiverr-private/i18n-react';
import {
    ButtonsGroup,
    ContactButton,
    SellerPageFederatedSeller,
    learnMoreModalOpenedAtom,
    MonitoringSources,
    ProAvatar,
    ProfileBadgeRatings,
    RespondTimeInfo,
    useMonitoring,
    usePortfolio,
    UserInfoContainer,
    useSellerData,
    useWorkExperience,
    useIsSellerOnline,
} from '@fiverr-private/seller_pages_toolbox';
import { Stack } from '@fiverr-private/layout_components';
import { ROLLOUTS } from '../../../server/middlewares/addRolloutsData/constants';
import { rolloutsAtom } from '../../atoms';
import { useSellerReviews } from '../../hooks/useSellerReviews';
import { SELLER_PAGE_SIGNING_TRIGGER_IDS, SELLER_PAGE_SIGNING_TRIGGER_PLACEMENTS } from '../SellerPage/constants';
import { useContactSellerModalUpdater } from '../ContactSellerModal/hooks/useContactSellerModal';
import { useScrollObserve } from './useScrollObserve';
import { TabName, scrollToTab } from './tab-names';
import { StickyHeaderProps } from './types';
import styles from './index.module.scss';

const OrcaTab = Tab as any;

const T_PREFIX = 'seller_page_perseus.sticky_header';

const OFFSET = 20;

const getHeaderHeight = (headerRef: React.MutableRefObject<any>) => {
    const height = headerRef.current ? headerRef.current.children[0]?.getBoundingClientRect().height : 0;
    return height ? height + OFFSET : 0;
};

const withHiddenClass = (condition: boolean) => (condition ? 'hidden' : '');

export const StickyHeader = ({ observeHideRef }: StickyHeaderProps) => {
    const headerRef = useRef(null);
    const { isPro, username, profileImageUrl, testimonials } = useSellerData(selectSellerSlice);
    const isOnline = useIsSellerOnline();
    const setShowModal = useContactSellerModalUpdater();
    const [shouldShow, setShouldShow] = useState(!observeHideRef);
    const [learnMoreModalOpened] = useAtom(learnMoreModalOpenedAtom);
    const [activeTab, setActiveTab] = useState(TabName.About);
    const { Providers, fireEvents } = useMonitoring();
    const { showPortfolio } = usePortfolio();
    const { showWorkExperience } = useWorkExperience(true);
    const reviews = useSellerReviews();
    const hasReviews = !!reviews?.buying_reviews?.reviews?.length || !!reviews?.selling_reviews?.reviews?.length;
    const hasTestimonials = (testimonials?.length || 0) > 0;
    const [rollouts] = useAtom(rolloutsAtom);
    const inQualasNewLevelSystemRollout = rollouts[ROLLOUTS.QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION];

    const tabNames = useMemo(() => values(TabName), []);
    const onTabIntersect = useCallback(
        (currentTab) => {
            if (currentTab && currentTab !== activeTab) {
                setActiveTab(currentTab);
            }
        },
        [activeTab]
    );

    const onTabClickBI = useCallback(
        (tabName) => {
            fireEvents({
                [Providers.Mixpanel]: {
                    type: 'seller page - sticky header - click on anchor',
                    'anchor tab name': tabName,
                },
            });
        },
        [Providers.Mixpanel, fireEvents]
    );

    const onSwitch = useCallback(
        (_idx, tabName) => {
            onTabClickBI(tabName);
            const offset = getHeaderHeight(headerRef);

            scrollToTab({
                tabId: tabName,
                offset: -offset,
            });
        },
        [headerRef, onTabClickBI]
    );

    const onRatingsClick = useCallback(() => {
        onSwitch(undefined, TabName.Reviews);
    }, [onSwitch]);

    // Observe id element, and scroll position to update active tab automatically
    useScrollObserve({
        shouldShow,
        elementIds: tabNames,
        headerHeight: getHeaderHeight(headerRef),
        onTabIntersect,
    });

    // Observe banner, and show sticky header if banner out of port view
    useEffect(() => {
        if (!observeHideRef || !observeHideRef.current) {
            return;
        }
        const observer = new IntersectionObserver(([bannerEl]) => {
            const { isIntersecting } = bannerEl;
            setShouldShow(!isIntersecting);
        }, {});

        observer.observe(observeHideRef.current);
        return () => observer.disconnect();
    }, [observeHideRef]);

    const headerClasses = {
        [styles.activeHeader]: shouldShow,
    };

    const containerClasses = {
        hidden: learnMoreModalOpened || !shouldShow,
    };

    const onContactOpen = () => {
        setShowModal(true);
    };

    return (
        <div className={classnames(styles.stickyHeader, headerClasses)} ref={headerRef}>
            <Section className={classnames(styles.sectionContainer, containerClasses, 'p-b-0')}>
                <Grid theme={(Grid as any).THEMES.TWELVE}>
                    <Col md={7} className="flex flex-items-center">
                        <div className={classnames(styles.mdVisible, 'm-r-24')}>
                            {isPro ? (
                                <ProAvatar />
                            ) : (
                                <Avatar
                                    size={56}
                                    username={username}
                                    src={profileImageUrl}
                                    isOnline={isOnline}
                                    showOnlineIndicator={isOnline}
                                    onlineIndicatorSize={8}
                                />
                            )}
                        </div>
                        <Stack direction="column" gap="2">
                            <ProfileBadgeRatings
                                usernameClassName="tbody-4"
                                onRatingsClick={onRatingsClick}
                                source={MonitoringSources.stickyHeader}
                                displayNameOnly
                                showSellerLevelNewBadges={inQualasNewLevelSystemRollout}
                            />
                            <div className={classnames(styles.mdVisible, 'co-text-darker')}>
                                <UserInfoContainer isOnline={isOnline} />
                            </div>
                        </Stack>
                    </Col>
                    <Col className={styles.mdVisible} md={5}>
                        <ButtonsGroup className={classnames(styles.buttonGroup, 'flex-end m-t-6')}>
                            <ContactButton
                                onContactOpen={onContactOpen}
                                source={MonitoringSources.stickyHeader}
                                triggerId={SELLER_PAGE_SIGNING_TRIGGER_IDS.CONTACT_SELLER}
                                triggerPlacement={SELLER_PAGE_SIGNING_TRIGGER_PLACEMENTS.SELLER_STICKY_HEADER}
                            />
                        </ButtonsGroup>
                        <div className={classnames(styles.responseInfo, 'm-t-16 flex flex-end tbody-6 co-text-medium')}>
                            <RespondTimeInfo />
                        </div>
                    </Col>
                </Grid>
                <div className="sticky-header-tabs">
                    <TabGroup key={activeTab} activeTab={activeTab as any} onSwitch={onSwitch}>
                        <OrcaTab title={<I18n k={`${T_PREFIX}.tabs.about`} />} name={TabName.About} />
                        <OrcaTab title={<I18n k={`${T_PREFIX}.tabs.services`} />} name={TabName.Services} />
                        <OrcaTab
                            className={withHiddenClass(!showPortfolio)}
                            title={<I18n k={`${T_PREFIX}.tabs.portfolio`} />}
                            name={TabName.Portfolio}
                        />
                        <OrcaTab
                            className={withHiddenClass(!showWorkExperience)}
                            title={<I18n k={`${T_PREFIX}.tabs.work_experience`} />}
                            name={TabName.WorkExperience}
                        />
                        <OrcaTab
                            className={withHiddenClass(!hasReviews)}
                            title={<I18n k={`${T_PREFIX}.tabs.reviews`} />}
                            name={TabName.Reviews}
                        />
                        <OrcaTab
                            className={withHiddenClass(!hasTestimonials)}
                            title={<I18n k={`${T_PREFIX}.tabs.testimonials`} />}
                            name={TabName.Testimonials}
                        />
                    </TabGroup>
                </div>
            </Section>
            <div className={classnames(styles.shadowBorder, containerClasses, 'box-shadow-z2')} />
        </div>
    );
};

StickyHeader.TabName = TabName;

function selectSellerSlice(seller: SellerPageFederatedSeller) {
    return {
        isPro: seller.isPro,
        username: seller.user.name,
        profileImageUrl: seller.user.profileImageUrl,
        testimonials: seller.testimonials,
    };
}
