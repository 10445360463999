import { useCallback, useRef, useState } from 'react';
import { useContactDataQuery } from '../../../hooks/useContactDataQuery';
import { ControlledPromise } from '../../../utils/ControlledPromise';

interface UseContactDataClickProps {
    username: string;
    userHasPendingState: boolean;
    isLoggedIn: boolean;
    isMD: boolean;
    isVisible: boolean;
    onError: () => void;
}

type ContactData = ReturnType<typeof useContactDataQuery>['modalInitialData'];

export const useContactDataClick = ({
    username,
    userHasPendingState,
    isLoggedIn,
    isMD,
    isVisible,
    onError,
}: UseContactDataClickProps) => {
    const promiseRef = useRef(new ControlledPromise<ContactData>());
    const [enableFetchData, setEnableFetchData] = useState(false);
    const [isContactClicked, setIsContactClicked] = useState(false);
    const shouldEnableFetch = Boolean(isVisible && isMD && enableFetchData && !userHasPendingState);
    const { isLoading, modalInitialData, refetchInitialData } = useContactDataQuery({
        username,
        isLoggedIn,
        enabled: shouldEnableFetch,
        onError: () => {
            onError();
            promiseRef.current.rejectPromise();
        },
        onSuccess: (data: ContactData) => promiseRef.current.resolvePromise(data),
    });

    const isContactClickReady = useCallback(() => {
        if (userHasPendingState || !isLoggedIn) {
            return promiseRef.current.resolvePromise(undefined);
        }
        setEnableFetchData(true);
        return promiseRef.current.promise;
    }, [promiseRef, isLoggedIn, userHasPendingState]);

    return {
        isLoading,
        isContactClicked,
        setIsContactClicked,
        modalInitialData,
        setEnableFetchData,
        refetchInitialData,
        isContactClickReady,
    };
};
