import React from 'react';
import { logger } from '@fiverr-private/obs';
import { I18n } from '@fiverr-private/i18n-react';
import { Heading } from '@fiverr-private/typography';
import { Stack } from '@fiverr-private/layout_components';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { LazyPortfolioWidget } from '@fiverr-private/portfolio';
import { SellerPageFederatedSeller, usePortfolio, useSellerData } from '@fiverr-private/seller_pages_toolbox';

export const SellerPagePortfolio = () => {
    const { username } = useSellerData(selectSellerSlice);
    const { showPortfolio } = usePortfolio();

    if (!showPortfolio) {
        return null;
    }

    return (
        <ErrorBoundary onError={onRenderError}>
            <Stack gap="4" direction="column">
                <Heading as="h4">
                    <I18n k="seller_page_perseus.portfolio.title" />
                </Heading>
                <LazyPortfolioWidget
                    componentProps={{
                        username,
                        cardSize: { md: 'md', lg: 'lg' },
                        thumbnailsPosition: { md: 'right' },
                        thumbnailsAmount: { sm: 5 },
                    }}
                />
            </Stack>
        </ErrorBoundary>
    );
};

const selectSellerSlice = (seller: SellerPageFederatedSeller) => ({
    username: seller.user.name,
});

const onRenderError = (originalError) => {
    const err = new Error('Failed while rendering the SellerPagePortfolio Component');
    logger.error(err, {
        cause: originalError,
    });
};
