import { Providers } from './monitoring-constants';
import { bigQuery, BQClickOptions } from './big-query';
import { mixpanel, MPClickOptions } from './mixpanel';
import { grafana, GrafanaOptions } from './grafana';
import { DefaultData } from './types';

export interface ClickEventsMap {
    [Providers.BigQuery]: BQClickOptions;
    [Providers.Mixpanel]: MPClickOptions;
    [Providers.Grafana]: GrafanaOptions;
}

export const fireClickEvents =
    (defaultData: DefaultData = {}) =>
    (eventsMap: Partial<ClickEventsMap> = {}) => {
        if (eventsMap.bigQuery) {
            bigQuery.click(eventsMap.bigQuery, defaultData);
        }

        if (eventsMap.mixpanel) {
            mixpanel.click(eventsMap.mixpanel, defaultData);
        }

        if (eventsMap.grafana) {
            grafana.count(eventsMap.grafana);
        }
    };
