import React from 'react';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { LearnMoreModal } from '@fiverr-private/seller_pages_toolbox';
import { LearnMoreButtonProps } from './types';
import { LearnMoreContent } from './LernMoreContent';

export const LearnMoreButton = ({ className, onRatingsClick }: LearnMoreButtonProps) => (
    <LearnMoreModal
        triggerChildren={
            <Button className={className} variant="outline" intent="secondary">
                <I18n k="seller_pages_toolbox.about.learn_more" />
            </Button>
        }
        content={<LearnMoreContent onRatingsClick={onRatingsClick} />}
    />
);
