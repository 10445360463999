import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import { getContext } from '@fiverr-private/fiverr_context';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { logger } from '@fiverr-private/obs';
import { useHydrationState } from '../../hooks/useHydrationState';
import { ProfileHeader } from '../ProfileHeader';
import { UrlContext } from '../../utils/UrlContext';
import { isBrowser } from '../../utils/isBrowser';
import { scrollToTab, TabName } from '../../utils/tab-names';
import styles from './index.module.scss';
import { SellerOverviewProps } from './types';

const onRenderError = (originalError) => {
    const err = new Error(`Failed while rendering the SellerOverview Component`);
    logger.error(err, {
        cause: originalError,
    });
};

export const SellerOverview = ({
    className,
    showSellerLevel = false,
    canEdit = false,
    showSellerLevelNewBadges,
}: SellerOverviewProps) => {
    const { isMobile } = getContext();
    const { isLG, isXL, isMD } = useBreakpoints();
    const { componentKey } = useHydrationState('SellerOverview');
    const isBig = isMobile ? false : !isBrowser() || isXL || isLG;
    const indicatorSize = isBig ? 16 : 12;
    let avatarSize = isBig ? 160 : 117;

    if (!isBig && !isMD) {
        avatarSize = 80;
    }

    const isFiverrChoice = UrlContext.isFiverrChoice();

    const onRatingsClick = useCallback(() => scrollToTab(TabName.Reviews), []);

    return (
        <ErrorBoundary onError={onRenderError}>
            <div className={classnames(className, styles.sellerOverview, styles.info)} key={componentKey}>
                <ProfileHeader
                    withH1PublicName
                    showSellerLevel={showSellerLevel}
                    showSellerLevelNewBadges={showSellerLevelNewBadges}
                    className={classnames({ 'flex flex-items-end': isBig })}
                    avatarClassName={classnames(styles.avatarContainer, styles.newView, {
                        'm-b-16': !isBig,
                        [styles.sm]: !isMD,
                        [styles.md]: isMD,
                    })}
                    avatarSize={avatarSize}
                    withAvatarBorder
                    isInModal={false}
                    isFiverrChoice={isFiverrChoice}
                    onlineIndicatorSize={indicatorSize}
                    onRatingsClick={onRatingsClick}
                    canEdit={canEdit}
                />
            </div>
        </ErrorBoundary>
    );
};
