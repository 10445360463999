import { attempt, isError } from 'lodash';
import { logger } from '@fiverr-private/obs';

export const tryInvoke = (func: (...args: any[]) => unknown, fallback: any) => {
    const result = attempt(func);

    if (isError(result)) {
        logger.error(result, {
            description: 'Error in function',
        });

        return fallback;
    }

    return result;
};
