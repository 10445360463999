import { capitalize, flow, startCase } from 'lodash';
import { mixpanel as mp } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import serialize, { Serializers } from '../serialize';
import { DefaultData } from './types';

export interface MPClickOptions {
    type: string;
    [key: string]: any;
}

export interface MPPageViewOptions {
    pageName?: string;
    type?: string;
    [key: string]: any;
}

const serializer = Serializers.StartCase;

const format = flow([startCase, capitalize]);

export const mixpanel = {
    event({ type, ...rest }: MPPageViewOptions) {
        this.pageView({ type, ...rest });
    },
    click({ type, ...rest }: MPClickOptions, defaultData: DefaultData = {}) {
        const { userId, organizationId } = getContext();

        const sellerId = defaultData?.seller?.user?.id;

        const payload = serialize(
            {
                ...rest,
                'user id': userId?.toString(),
                'is guest': !userId,
                'organization id': organizationId,
                'seller id': sellerId?.toString(),
                'page name': defaultData.pageName,
            },
            serializer
        );
        const eventName = format(type);
        mp.track(eventName, payload);
    },
    pageView({ type, ...rest }: MPPageViewOptions, defaultData: DefaultData = {}) {
        const { userId, organizationId } = getContext();

        const sellerId = defaultData?.seller?.user?.id;

        const payload = serialize(
            {
                ...rest,
                'user id': userId?.toString(),
                'is guest': !userId,
                'organization id': organizationId,
                'seller id': sellerId?.toString(),
                'page name': defaultData.pageName,
            },
            serializer
        );

        if (type) {
            mp.track(format(type), payload);
        } else {
            mp.trackPageView(payload);
        }
    },
    redirectToInbox({ priorInteraction, source }: { source: string; priorInteraction: boolean }) {
        this.event({
            type: 'redirected to inbox from contact button',
            source,
            buyerType: priorInteraction ? 'Repeated' : 'First time',
        });
    },
};
