import React, { useState } from 'react';
import classnames from 'classnames';
import { Carousel } from '@fiverr-private/orca';
import { theme } from '@fiverr-private/theme';
import { SkillCheckIcon } from '@fiverr-private/icons';
import { CertifiedTestimonial } from '@fiverr-private/certified_testimonial';
import { getContext } from '@fiverr-private/fiverr_context';
import { Container } from '@fiverr-private/layout_components';
import { I18n } from '@fiverr-private/i18n-react';
import { Heading } from '@fiverr-private/typography';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import { useCurrentPageName } from '../../hooks/useCurrentPageName';
import { TestimonialsProps } from './types';

import { testimonialClassName, touchSlideWidthClassName, overTwoTestimonialsClassName } from './styles.css';
import styles from './styles.module.scss';

const ApproveIcon = <SkillCheckIcon size={16} color={theme.colors.business_1200} />;

export const Testimonials: React.FC<TestimonialsProps> = ({ titleClassName }) => {
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const onScroll = ({ canScrollLeft, canScrollRight }) => {
        setCanScrollLeft(canScrollLeft);
        setCanScrollRight(canScrollRight);
    };

    const { isTouch } = getContext();
    const { testimonials, sellerId } = useSellerData(selectSellerSlice);
    const pageName = useCurrentPageName();

    const withCompanySpace = testimonials.some((testimonial) => !!testimonial.client?.company);
    const overTwoTestimonials = testimonials.length > 2;

    const biParams = {
        pageName,
        sellerId,
    };

    return (
        <Container>
            <Heading as="h5" size="h_sm" marginBottom="5" className={titleClassName}>
                <I18n k="seller_pages_toolbox.testimonials.title" />
            </Heading>
            <Carousel
                slideClassName={classnames({
                    [touchSlideWidthClassName]: isTouch,
                    [overTwoTestimonialsClassName]: !isTouch && overTwoTestimonials,
                })}
                carouselClassName={classnames(styles.carousel, {
                    [styles.carouselCanScrollLeft]: canScrollLeft,
                    [styles.carouselCanScrollRight]: canScrollRight,
                    [styles.isTouch]: isTouch,
                })}
                slides={testimonials}
                componentProps={{
                    className: testimonialClassName,
                    approveIcon: ApproveIcon,
                    withCompanySpace,
                    biParams,
                }}
                Component={CertifiedTestimonial}
                onScroll={onScroll}
                swipeMode={isTouch}
                {...(!isTouch && {
                    slidesToShow: 2,
                })}
            />
        </Container>
    );
};

const selectSellerSlice = (seller: FederatedSeller) => ({
    sellerId: seller.user.id,
    testimonials: seller.testimonials,
});
