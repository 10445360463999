import React, { useCallback } from 'react';
import { LazyComponent as LazyComponentOrca } from '@fiverr-private/orca';
import { logger, stats } from '@fiverr-private/obs';
import { useMetricBase } from '../../hooks/useMetricBase';

type LazyComponentOrcaProps = React.ComponentProps<typeof LazyComponentOrca>;

interface LazyComponentProps
    extends Pick<LazyComponentOrcaProps, 'lazyImport' | 'componentProps'>,
        React.PropsWithChildren<Partial<Omit<LazyComponentOrcaProps, 'lazyImport' | 'componentProps'>>> {
    moduleName: string;
}

export const LazyComponent: React.FC<LazyComponentProps> = ({ moduleName, children, ...props }) => {
    const statsPrefix = useMetricBase();

    const onError = useCallback(
        (e) => {
            logger.warn(`Failed to lazy load component "${moduleName}"`, e);
            stats.count(statsPrefix, 'error');
        },
        [moduleName, statsPrefix]
    );

    const componentProps = children ? Object.assign(props.componentProps, { children }) : props.componentProps;

    return <LazyComponentOrca {...props} componentProps={componentProps} onError={onError} />;
};
