import React from 'react';
import classnames from 'classnames';
import { UserIcon } from '@fiverr-private/icons';
import { Icon } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import { grey_1200 } from '@fiverr-private/sass/helpers';
import { DATETIME_ELEMENT_STYLES, useLocalizationContext } from '../../hooks/LocalizationContext';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import styles from './style.module.scss';

const T_KEY = 'seller_pages_toolbox.details';

export const MemberSince = () => {
    const { joinedAt } = useSellerData(selectSellerSlice);

    const { dateTimeFormat } = useLocalizationContext();

    if (!joinedAt) {
        return null;
    }

    const memberSince = dateTimeFormat(new Date(joinedAt * 1000), {
        year: DATETIME_ELEMENT_STYLES.NUMERIC,
        month: DATETIME_ELEMENT_STYLES.SHORT,
    });

    return (
        <div className={classnames(styles.memberSince, 'flex-items-center')}>
            <Icon size={16} color={grey_1200}>
                <UserIcon />
            </Icon>
            <div className={'m-l-12 tbody-5 text-semi-bold'}>
                <I18n k={`${T_KEY}.member_since`} params={{ date: memberSince }} />
            </div>
        </div>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        joinedAt: seller.user.joinedAt,
    };
}
