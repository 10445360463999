import React from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Content, ContentsWrapper, Header } from '../MoreInfoModal';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import CertificationIcon from './Certificate.svg';
import classes from './index.module.scss';

const T_KEY = 'seller_pages_toolbox.modal.certifications';

interface CertificationsProps {
    customTitle?: React.ReactNode;
    contentTitleClassName?: string;
    subtitleClassName?: string;
}

export const Certifications = ({
    customTitle = null,
    contentTitleClassName,
    subtitleClassName,
}: CertificationsProps) => {
    const { certifications } = useSellerData(selectSellerSlice);

    return (
        <div className={classes.wrapper}>
            {customTitle ?? <Header title={<I18n k={`${T_KEY}.title`} />} />}
            <ContentsWrapper asElement="ul" ariaLabel="Certifications List">
                {certifications.map((certification) => (
                    <Content
                        asElement="li"
                        subtitleClassName={classnames(classes.subtitle, subtitleClassName)}
                        titleClassName={contentTitleClassName}
                        icon={<img src={CertificationIcon} />}
                        key={`${certification.year}${certification.receivedFrom}${certification.certificationName}`}
                        title={<>{`${certification.receivedFrom} ${certification.certificationName}`}</>}
                        description={
                            <>
                                <I18n k={`${T_KEY}.graduated`} /> {certification.year}
                            </>
                        }
                    />
                ))}
            </ContentsWrapper>
        </div>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        certifications: seller.certifications,
    };
}
