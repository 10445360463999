import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { noop, cloneDeep } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import {
    MachineTranslationButton,
    getTranslateOnLoad,
    setTranslateOnLoad,
    raiseErrorToast,
    translate,
} from '@fiverr-private/machine_translation';
import { GetUgcFunction, UGCTranslateButtonProps } from './types';

export const UGCTranslateButton = ({
    componentName,
    sourceName,
    translateURL,
    showDisclaimerText = true,
    getUgc,
    onSuccess = noop,
}: UGCTranslateButtonProps) => {
    const { userId } = getContext();

    const translateOnLoad = getTranslateOnLoad();

    const [toggle, setToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const originalData = useRef<ReturnType<GetUgcFunction> | null>(null);
    const translatedUGC = useRef<ReturnType<GetUgcFunction> | null>(null);

    const sourceInfo = useMemo(
        () => ({
            name: sourceName,
            component: { name: componentName },
            user: { id: userId },
        }),
        [sourceName, componentName, userId]
    );

    const translateUGC = useCallback(async () => {
        const ugc = getUgc();

        if (!originalData.current) {
            originalData.current = cloneDeep(ugc);
        }

        if (translatedUGC.current !== null) {
            onSuccess(translatedUGC.current, true);
            setToggle(true);
            return;
        }

        try {
            setLoading(true);

            const params = {
                text: ugc,
                url: translateURL,
                retry: true,
                sourceInfo,
            };

            const { translatedBlob } = await translate(params);
            translatedUGC.current = translatedBlob;

            onSuccess(translatedBlob, true);
            setToggle(true);
        } catch (e) {
            raiseErrorToast();
        } finally {
            setLoading(false);
        }
    }, [translateURL, sourceInfo, getUgc, onSuccess]);

    const onTranslateButtonClick = useCallback(() => {
        if (toggle) {
            onSuccess(originalData.current || {}, false);
            setTranslateOnLoad(false);
            setToggle(false);
        } else {
            translateUGC();
            setTranslateOnLoad(true);
        }
    }, [toggle, translateUGC, onSuccess]);

    useEffect(() => {
        if (translateOnLoad) {
            translateUGC();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MachineTranslationButton
            toggled={toggle}
            loading={loading}
            onClick={onTranslateButtonClick}
            sourceInfo={sourceInfo}
            showDisclaimerText={showDisclaimerText}
            style={MachineTranslationButton.STYLES.LEAN_WITH_DISCLAIMER}
        />
    );
};
