import { bigQuery } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';

export type FreelancerPageType = 'profession' | 'regular';

export const reportBqClickEvent = ({ freelancer_page_type }: { freelancer_page_type: FreelancerPageType }) => {
    const { userId, pageCtxId } = getContext();
    const payload = {
        group: 'user_action',
        event_triggered_by: 'click',
        action: { type: 'click' },
        type: 'clicked_on_hourly_upgrade_Freelancer',
        freelancer_page_type,
        user: { id: userId },
        page: {
            ctx_id: pageCtxId,
            element: {
                name: 'Freelancer',
            },
        },
        component: {
            type: 'Button',
            name: 'hourly_upgrade',
            parent: { id: 'freelancer_page' },
        },
    };

    bigQuery.send(payload);
};

export const reportBqImpressionEvent = ({ freelancer_page_type }: { freelancer_page_type: FreelancerPageType }) => {
    const { userId, pageCtxId } = getContext();
    const payload = {
        group: 'banner_impression',
        event_triggered_by: 'impression',
        action: { type: 'impression' },
        type: 'viewed_hourly_upgrade_Freelancer',
        freelancer_page_type,
        user: { id: userId },
        page: {
            ctx_id: pageCtxId,
            element: {
                name: 'Freelancer',
            },
        },
        component: {
            type: 'Button',
            name: 'hourly_upgrade',
            parent: { id: 'freelancer_page' },
        },
    };

    bigQuery.send(payload);
};
