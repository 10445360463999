import axios, { AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { stats } from '@fiverr-private/obs';
import { isDev } from '../utils/isDev';
import { AxiosWrapperOptions } from './types';

const DEFAULT_TIMEOUT = 400;

const get = async <T = any>(
    url: string,
    options: AxiosWrapperOptions = {},
    metricRequestName: string
): Promise<AxiosResponse<T>> => {
    const startTime = Date.now();
    let timeout;

    if (isDev) {
        timeout = 1000;
    } else {
        timeout = options.timeout || DEFAULT_TIMEOUT;
    }

    const restOptions = omit(options, 'timeout');

    const controller = new AbortController();

    const to = setTimeout(() => {
        controller.abort();
    }, timeout);

    let data;

    try {
        data = await axios.get(url, {
            signal: controller.signal,
            ...restOptions,
        });

        clearTimeout(to);

        const timeTaken = Date.now() - startTime;
        stats.time(`packages.seller_pages`, `server.api.${metricRequestName}.200`, timeTaken);

        return data;
    } catch (err: any) {
        clearTimeout(to);
        (err as any).details = {
            url,
            requestAborted: axios.isCancel(err),
            timeout,
            options,
        };

        let statusCode = err.response?.status;

        if (statusCode === 200) {
            statusCode = undefined;
        }

        const timeTaken = Date.now() - startTime;
        const suffix = axios.isCancel(err) ? 'timeout' : statusCode;
        stats.time(`packages.seller_pages`, `server.api.${metricRequestName}.${suffix}`, timeTaken);

        throw err;
    }
};

export const axiosWrapper = {
    get,
};
