import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { ContentProps, ContentsWrapperProps, HeaderProps } from './types';

export const Header = ({ title, className }: HeaderProps) => (
    <div className={classnames(styles.header, className, 'm-b-12 text-bold co-text-darker')}>{title}</div>
);

export const ContentsWrapper = ({ className, children, asElement = 'div', ariaLabel }: ContentsWrapperProps) => {
    const Component = asElement;

    return (
        <Component aria-label={ariaLabel} className={classnames(className, styles.contentsWrapper, 'flex flex-col')}>
            {children}
        </Component>
    );
};

export const Content = ({
    icon,
    subtitle,
    title,
    description,
    subtitleClassName,
    titleClassName = 'tbody-6',
    descriptionClassName,
    asElement = 'div',
}: ContentProps) => {
    const Component = asElement;

    return (
        <Component className={classnames(styles.content, 'flex flex-items-start')}>
            {icon}
            <div
                className={classnames(styles.info, {
                    [styles.withIcon]: !!icon,
                })}
            >
                <div aria-label="Title" className={classnames(styles.title, 'text-semi-bold', titleClassName)}>
                    {title}
                </div>
                {subtitle && (
                    <div
                        aria-label="Sub Title"
                        className={classnames(styles.subtitle, subtitleClassName, 'tbody-6 co-text-darker')}
                    >
                        {subtitle}
                    </div>
                )}
                {description && (
                    <div
                        aria-label="Description"
                        className={classnames(styles.description, descriptionClassName, 'tbody-6')}
                    >
                        {description}
                    </div>
                )}
            </div>
        </Component>
    );
};
