import axios, { Method } from 'axios';
import { stats } from '@fiverr-private/obs';

/**
 * Transfer url or path string to metric name - extract path, replace slashes, remove special characters
 * EX. "http://fiverr.com/api@!~/get_tasks/" -> "api.get_tasks"
 */
const getDefaultMetricName = (urlString) => {
    let metricName;

    const format = (str) =>
        str
            .replace(/^\/|\/$/g, '')
            .replaceAll('/', '.')
            .replace(/[^a-z0-9._-]/gi, '');

    try {
        const url = new URL(urlString);
        metricName = format(url.pathname);
    } catch (error) {
        metricName = format(urlString);
    }

    return metricName;
};

const safeRequest = async (
    method: Method,
    url: string,
    metricName: string | null = null,
    additionalParams = {},
    timeout = 3000
) => {
    let data;
    let statusForMonitor;
    const BaseMetric = `packages.seller_pages_toolbox.${metricName || getDefaultMetricName(url)}`;

    try {
        const startTime = Date.now();
        const resp = await axios({ method, url, timeout, ...additionalParams });
        statusForMonitor = resp.status;
        data = resp.data;

        stats.time(BaseMetric, 'response_time', startTime);
    } catch (error: any) {
        if (error.isAxiosError) {
            if (error.response && error.response.status) {
                statusForMonitor = error.response.status;
            } else {
                statusForMonitor = 'timeout';
            }
        } else {
            statusForMonitor = 'compute_error';
        }
    }

    stats.count(BaseMetric, `status.${statusForMonitor}`);
    return data;
};

export default safeRequest;
