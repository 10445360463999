import { useMemo } from 'react';
import { type SellerRole } from '../../gql-operations/gql-generated';
import { useSelectedRoleId } from './useSelectedRoleId';
import { useSellerRoles } from './useSellerRoles';

export const useSelectedRole = (): SellerRole | null => {
    const sellerRoles = useSellerRoles();
    const selectedRoleId = useSelectedRoleId();

    const selectedRole = useMemo(() => {
        if (!selectedRoleId) return null;
        return sellerRoles.find((role) => role.id === selectedRoleId) || null;
    }, [sellerRoles, selectedRoleId]);

    return selectedRole;
};
