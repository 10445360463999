import React from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Header, ContentsWrapper, Content } from '../MoreInfoModal';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import EducationIcon from './Education.svg';
import { DEGREE_TITLES } from './consts';
import classes from './index.module.scss';

const T_KEY = 'seller_pages_toolbox.modal.education';

interface EducationsProps {
    customTitle?: React.ReactNode;
    contentTitleClassName?: string;
    subtitleClassName?: string;
}

export const Educations = ({ customTitle = null, contentTitleClassName, subtitleClassName }: EducationsProps) => {
    const { activeEducations } = useSellerData(selectSellerSlice);

    return (
        <div className={classes.wrapper}>
            {customTitle ?? <Header title={<I18n k={`${T_KEY}.title`} />} />}
            <ContentsWrapper ariaLabel="Educations List" asElement="ul">
                {activeEducations.map((education) => (
                    <Content
                        asElement="li"
                        subtitleClassName={classnames(classes.subtitle, subtitleClassName)}
                        titleClassName={contentTitleClassName}
                        icon={<img src={EducationIcon} />}
                        key={`${education.degree}${education.toYear}${education.school}`}
                        title={education.school}
                        subtitle={
                            <>
                                {DEGREE_TITLES[education.degreeTitle]} <I18n k={`${T_KEY}.degree`} /> {education.degree}
                            </>
                        }
                        description={
                            <>
                                <I18n k={`${T_KEY}.graduated`} /> {education.toYear}
                            </>
                        }
                    />
                ))}
            </ContentsWrapper>
        </div>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        activeEducations: seller.activeEducations,
    };
}
