import { UseQueryResult, useQuery } from 'react-query';
import { debounce, noop } from 'lodash';
import { fetchInitialData } from '@fiverr-private/send_message';
import { logger } from '@fiverr-private/obs';

const QUERY_BASE_KEY = 'contact-data';

const logQueryError = debounce((originalError) => {
    const err = new Error('Failed to fetch seller contact data');

    logger.error(err, {
        cause: originalError,
    });
});

// Since there is no rigorous typings contract all the fields are set optional
export interface ModalInitialData {
    recipient?: {
        isOnline?: boolean;
        utcOffset?: number;
        responseTime?: string;
        responseTimeInHrs?: number;
        profilePhoto?: string;
        proficientLanguages?: Record<string, string>;
        countryCode?: string;
        offersHourlyRate?: boolean;
    };
    sender?: {
        username?: string;
        hasApp?: boolean;
        isRnc?: boolean;
    };
    priorInteraction?: boolean;
    attachmentsUploadConfig?: {
        objectName?: string;
        attachmentName?: string;
        getPolicyUrl?: string;
        createPendingAttachmentIdUrl?: string;
    };
}

// WARN: fetchInitialContactData fails if user has pending state
// need to discuss with owner of send_message package
export const useContactDataQuery = ({ username, isLoggedIn, enabled, onSuccess = noop, onError = noop }) => {
    const {
        isLoading,
        data: modalInitialData,
        refetch: refetchInitialData,
    }: UseQueryResult<ModalInitialData> = useQuery(
        [QUERY_BASE_KEY, username, isLoggedIn],
        () => (isLoggedIn ? fetchInitialData(username) : null),
        {
            enabled,
            onSuccess,
            onError: (e) => {
                logQueryError(e);
                onError(e);
            },
        }
    );

    return {
        isLoading,
        modalInitialData,
        refetchInitialData,
    };
};
