import { noop } from 'lodash';
import { useMutation } from 'react-query';
import { setDisplayNameAndOneLiner } from '../apis/setDisplayNameAndOneLiner';

/**
 * @param {object} props
 * @param {() => void} props.onSuccess
 * @param {() => void} props.onError
 */
export default ({ onSuccess = noop, onError = noop }) =>
    useMutation(
        /** @type {typeof setDisplayNameAndOneLiner} */
        ({ displayName, oneLiner, locale }) => setDisplayNameAndOneLiner({ displayName, locale, oneLiner }),
        {
            onSuccess,
            onError,
        }
    );
