import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Typography } from '@fiverr-private/typography';
import { Container } from '@fiverr-private/layout_components';
import { text } from './styles.ve.css';

export const SellerTopRatedBadge = () => (
    <Container backgroundColor="yellow_300" borderRadius="sm" paddingX="1.5" height="fit-content" alignSelf="center">
        <Typography className={text} color="orange_1200" fontSize="b_sm" fontWeight="semibold">
            <I18n k="seller_pages_toolbox.top_rated" />
        </Typography>
    </Container>
);
