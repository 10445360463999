import React from 'react';
import classNames from 'classnames';
import { logger } from '@fiverr-private/obs';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import { ProSubcategories } from './ProSubcategories';
import { PropositionPoints } from './PropositionPoints';
import { ProOverviewProps, SPACING_MODES } from './types';
import classes from './index.module.scss';

const onRenderError = (originalError) => {
    const err = new Error('Failed while rendering the ProOverview Component');
    logger.error(err, {
        cause: originalError,
    });
};

export const ProOverview = ({ className, spacingMode = SPACING_MODES.NORMAL }: ProOverviewProps) => {
    const { proSubCategories } = useSellerData(selectSellerSlice);

    return (
        <ErrorBoundary onError={onRenderError}>
            <div
                className={classNames(
                    classes.container,
                    {
                        'm-b-12': spacingMode === SPACING_MODES.NORMAL,
                        [classes.largeSpacing]: spacingMode === SPACING_MODES.LARGE,
                    },
                    className
                )}
            >
                <PropositionPoints
                    className={classNames(classes.propositionPoints, {
                        'm-b-8': spacingMode === SPACING_MODES.NORMAL,
                        [classes.largeSpacing]: spacingMode === SPACING_MODES.LARGE,
                    })}
                    itemClassName={classNames({
                        [classes.point]: spacingMode === SPACING_MODES.LARGE,
                    })}
                />
                {proSubCategories.length > 0 && (
                    <ProSubcategories
                        titleClassName={classNames({
                            'm-b-12': spacingMode === SPACING_MODES.LARGE,
                        })}
                    />
                )}
            </div>
        </ErrorBoundary>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        proSubCategories: seller.proSubCategories,
    };
}
