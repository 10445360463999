import React from 'react';
import { LazyComponent } from '../LazyComponent/LazyComponent';
import { LearnMoreProps } from './types';

const lazyImport = () => import(/* webpackChunkName: 'LearnMoreModal' */ './index');

export const LazyLearnMoreModal: React.FC<LearnMoreProps> = ({ children, ...props }) => (
    <LazyComponent moduleName="LearnMoreModal" placeholder={children} componentProps={props} lazyImport={lazyImport}>
        {children}
    </LazyComponent>
);
