import { useState, useEffect, useLayoutEffect } from 'react';
import { identity, isEmpty, throttle } from 'lodash';
import { getWindow } from '@fiverr-private/seller_pages_toolbox';

export const useScrollObserve = ({ shouldShow, elementIds, headerHeight, onTabIntersect }) => {
    const [scrollMap, setScrollMap] = useState(undefined);

    useEffect(() => {
        if (!shouldShow) {
            return;
        }

        const elementsMap = elementIds
            .filter((id) => document.getElementById(id))
            .reduce((acc, id) => {
                const el = document.getElementById(id) as HTMLElement;
                const scrollToEl = el.offsetTop - headerHeight;
                acc[scrollToEl] = id;
                return acc;
            }, {});

        setScrollMap(elementsMap);
    }, [shouldShow, elementIds, headerHeight]);

    useLayoutEffect(() => {
        const onScroll = throttle(() => {
            if (!scrollMap || isEmpty(scrollMap) || !getWindow()) {
                return;
            }

            const valuesFrom = Object.keys(scrollMap).map(Number);

            const tabScroll = valuesFrom.find((from, i) => {
                const to = valuesFrom[i + 1];
                const isFirstSection = i === 0 && window.scrollY < from;
                const isMiddleSection = to && window.scrollY >= from && window.scrollY < to;
                const isLastSection = !to && i + 1 === valuesFrom.length && window.scrollY >= from;

                return [isFirstSection, isMiddleSection, isLastSection].find(identity);
            }) as number;

            const currentTab = scrollMap[tabScroll];
            onTabIntersect(currentTab);
        }, 500);

        getWindow()?.addEventListener('scroll', onScroll);

        return () => {
            onScroll.cancel();
            getWindow()?.removeEventListener('scroll', onScroll);
        };
    }, [scrollMap, onTabIntersect]);
};
