import { LanguageProficiencyLevel } from '../../gql-operations/gql-generated';

const LanguageLevelMap = {
    [LanguageProficiencyLevel.Basic]: 'level_1',
    [LanguageProficiencyLevel.Conversational]: 'level_2',
    [LanguageProficiencyLevel.Fluent]: 'level_3',
    [LanguageProficiencyLevel.NativeOrBilingual]: 'level_4',
};

export const getLanguageCodeLevelKey = (languageLevel) =>
    LanguageLevelMap[languageLevel] ? `languages.proficiencies.${LanguageLevelMap[languageLevel]}` : '';
