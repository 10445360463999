import React from 'react';
import classnames from 'classnames';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import {
    Divider,
    SellerPageFederatedSeller,
    MemberSince,
    SpokenLanguages,
    useSellerData,
} from '@fiverr-private/seller_pages_toolbox';
import styles from './index.module.scss';

export const SellerProfile = () => {
    const { languages } = useSellerData(selectSellerSlice);

    const { isMD } = useBreakpoints();

    return (
        <div className={classnames(styles.container, { 'box-shadow-z1': isMD })}>
            <MemberSince />
            {languages.length > 0 && (
                <>
                    <Divider />
                    <SpokenLanguages />
                </>
            )}
        </div>
    );
};

function selectSellerSlice(seller: SellerPageFederatedSeller) {
    return {
        languages: seller.user.languages,
    };
}
