import { noop } from 'lodash';

export class ControlledPromise<T> {
    promise: Promise<T>;
    resolvePromise: (value: T | PromiseLike<T>) => void = noop;
    rejectPromise: (reason?: any) => void = noop;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolvePromise = resolve;
            this.rejectPromise = reject;
        });
    }
}
