import { DegreeTitle } from '../../gql-operations/gql-generated';

export const DEGREE_TITLES: Record<DegreeTitle, string> = {
    CERTIFICATE: 'Certificate',
    BA: 'B.A.',
    BARCH: 'BArch',
    BFA: 'BFA',
    BSC: 'B.Sc.',
    MA: 'M.A.',
    MBA: 'M.B.A.',
    MFA: 'MFA',
    MSC: 'M.Sc.',
    JD: 'J.D.',
    MD: 'M.D.',
    PHD: 'Ph.D',
    LLB: 'LLB',
    LLM: 'LLM',
    ASSOCIATE: 'Associate',
    BM: 'BM',
    OTHER: 'Other',
};
