import React, { forwardRef, Ref } from 'react';
import classNames from 'classnames';
import { Tag } from '@fiverr-private/data_display';
import { ListItem } from '@fiverr-private/list';

interface SkillItemProps {
    isHidden?: boolean;
    className?: string;
    skillText?: string;
    skillUrl?: string;
    shouldLink?: boolean;
}

export const SkillItem = forwardRef(
    ({ skillText, skillUrl, isHidden = false, className, shouldLink }: SkillItemProps, ref: Ref<HTMLElement>) => (
        <ListItem hidden={isHidden} ref={ref} className={classNames(className, { hidden: isHidden })}>
            <Tag
                variant="outline"
                size="sm"
                ariaLabel={skillText}
                href={shouldLink && skillUrl ? skillUrl : undefined}
                target="_blank"
            >
                {skillText}
            </Tag>
        </ListItem>
    )
);

SkillItem.displayName = 'ForwardRef(SkillItem)';
