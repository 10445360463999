import React, { SyntheticEvent, useEffect, useState } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Modal, StatusToast } from '@fiverr-private/orca';
import { Button, Input, Tag } from '@fiverr-private/fit';
import { I18n, translate } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import { displayName as displayNameModule } from '@fiverr-private/seller_profile';
import { logger } from '@fiverr-private/obs';
import { useMonitoring } from '../../utils/monitoring';
import useSetDisplayNameAndOneLiner from '../../hooks/useSetDisplayNameAndOneLiner';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import { useSellerDataUpdater } from '../../hooks/useSellerDataUpdater';
import { DisplayNameModalBodyProps, DisplayNameModalProps, onDisplayNameSaveSuccess } from './types';
import styles from './index.module.scss';

const TRANSLATION_KEY = 'seller_pages_toolbox.display_name';
const LOCALE = 'en';

const ModalContent = ({ username, fullName, displayName, oneLiner, onClose = noop }: DisplayNameModalBodyProps) => {
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            displayName,
            oneLiner,
        },
    });
    const { fireEvents, Providers } = useMonitoring();
    const setSellerData = useSellerDataUpdater();
    const { sellerName } = useSellerData(selectSeller);
    const [availableVariant, setAvailableVariant] = useState('');

    useEffect(() => {
        const suggestedName = displayNameModule.suggest(fullName as string | undefined);
        if (suggestedName && suggestedName !== displayName) {
            setAvailableVariant(suggestedName);
        }
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'view edit public name modal',
            },
        });
    }, [fireEvents, fullName, displayName, Providers]);

    const onSaveBI = (isSuccess = true) => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'saved public name modal',
                'Is success': isSuccess,
                'Public name presence': !!getValues('displayName'),
                'Tagline presence': !!getValues('oneLiner'),
            },
        });
    };

    const onSaveSucess: onDisplayNameSaveSuccess = (_data, { oneLiner: newOneLiner, displayName: newDisplayName }) => {
        setSellerData((seller: FederatedSeller) => {
            seller.user.profile.displayName = newDisplayName;
            seller.oneLinerTitle = newOneLiner;
        });

        onSaveBI();

        StatusToast.raise({
            message: <I18n k={`${TRANSLATION_KEY}.success`} />,
        });
        onClose();
    };

    const onSaveError = (originalError) => {
        const err = new Error(`Failed to update displayName and oneLiner data for seller with username: ${sellerName}`);

        logger.error(err, {
            cause: originalError,
        });

        onSaveBI(false);

        StatusToast.raise({
            message: <I18n k={`${TRANSLATION_KEY}.error`} />,
            theme: (StatusToast as any).THEMES.ERROR,
        });
    };

    const { mutate: setDisplayNameAndOneLiner } = useSetDisplayNameAndOneLiner({
        onSuccess: onSaveSucess,
        onError: onSaveError,
    });

    const onTagSelect = (newVal: string) => {
        setValue('displayName', newVal);
        setAvailableVariant('');
    };

    const onSubmitHandler = (data: any) => {
        const { displayName } = data;
        setDisplayNameAndOneLiner({
            ...data,
            displayName: displayNameModule.format(displayName),
            locale: LOCALE,
        });
    };

    const onCancelBI = () => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'closed public name modal',
            },
        });
    };

    const onCloseHandler = (e: SyntheticEvent) => {
        e.preventDefault();
        onCancelBI();
        onClose();
    };

    const onViewLinkBI = () => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'public name modal clicked HC article',
            },
        });
    };

    const onSubmitBI = () => {
        if (!isValid) {
            onSaveBI(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Modal.Header hasCloseBtn>
                <I18n k={`${TRANSLATION_KEY}.title`} />
            </Modal.Header>
            <Modal.Body>
                <div className="co-text-dark">
                    <I18n k={`${TRANSLATION_KEY}.subtitle`} />
                </div>
                <div className="m-t-32 flex flex-items-center flex-start">
                    <div className="tbody-5 text-semi-bold">
                        <I18n k={`${TRANSLATION_KEY}.username`} />
                    </div>
                    <div className="m-l-12">{username}</div>
                </div>
                <div className="m-t-4">
                    <I18n k={`${TRANSLATION_KEY}.username_subtitle`} />
                    <a
                        href={pathfinder('help_center_article', { article_id: 360010140217 })}
                        className={classNames(styles.link, 'm-l-4')}
                        onClick={onViewLinkBI}
                    >
                        <I18n k={`${TRANSLATION_KEY}.learn_more`} />
                    </a>
                </div>
                <div className="m-t-32">
                    <div className="tbody-5 text-semi-bold flex flex-items-center flex-start">
                        <I18n k={`${TRANSLATION_KEY}.display_name.title`} />
                        <Tag className="m-l-8" color={(Tag as any).COLORS.PINK}>
                            <I18n k={`${TRANSLATION_KEY}.new`} />
                        </Tag>
                    </div>
                </div>
                <div className="m-t-4">
                    <I18n
                        k={`${TRANSLATION_KEY}.display_name.subtitle`}
                        templates={{
                            semibold: (text) => <span className="text-semi-bold">{text}</span>,
                        }}
                    />
                    <a
                        href={pathfinder('help_center_article', { article_id: 13409224346257 })}
                        className={classNames(styles.link, 'm-l-4')}
                    >
                        <I18n k={`${TRANSLATION_KEY}.display_name.learn_more`} />
                    </a>
                </div>
                <br />
                <div className="tbody-6">
                    <I18n k={`${TRANSLATION_KEY}.display_name.secondary_subtitle`} />
                </div>
                <div className="m-t-8">
                    <Input
                        isError={!!errors?.displayName?.message}
                        data-testid="displayName"
                        className={classNames(styles.input, 'display-name')}
                        placeholder={translate(`${TRANSLATION_KEY}.display_name.placeholder`)}
                        {...register('displayName', {
                            validate: (value) => {
                                if (value) {
                                    return displayNameModule.validate(value).errorMessage;
                                }

                                return false;
                            },
                        })}
                    />

                    {errors?.displayName?.message && (
                        <div className={classNames(styles.error, 'tbody-6 m-t-8')}>{errors?.displayName?.message}</div>
                    )}

                    {availableVariant && (
                        <div className="flex flex-start flex-items-center m-t-12">
                            <div className={classNames(styles.suggested, 'co-text-light tbody-7 text-bold')}>
                                <I18n k={`${TRANSLATION_KEY}.suggested`} />:
                            </div>
                            <div className="flex flex-items-center flex-start">
                                <div
                                    key={availableVariant}
                                    data-testid="tag"
                                    onClick={() => onTagSelect(availableVariant)}
                                >
                                    <Tag
                                        className={classNames(styles.tag, 'm-l-8')}
                                        size={(Tag as any).SIZES.SMALL}
                                        color={(Tag as any).COLORS.LIGHT_GREY}
                                    >
                                        {availableVariant}
                                    </Tag>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="m-t-32">
                    <div className="tbody-5 text-semi-bold">
                        <I18n k={`${TRANSLATION_KEY}.one_liner.title`} />
                    </div>
                </div>
                <div className="m-t-4">
                    <I18n k={`${TRANSLATION_KEY}.one_liner.subtitle`} />
                </div>
                <div className="m-t-8">
                    <Input
                        isError={!!errors?.oneLiner}
                        className={styles.input}
                        data-testid="oneLiner"
                        placeholder={translate(`${TRANSLATION_KEY}.one_liner.placeholder`)}
                        {...register('oneLiner', {
                            maxLength: 70,
                            minLength: 3,
                            pattern: /^[a-zA-Z0-9\s,?:'"!]+$/,
                            required: true,
                        })}
                    />

                    {errors?.oneLiner && (
                        <div className={classNames(styles.error, 'tbody-6 m-t-8')}>
                            <I18n k={`${TRANSLATION_KEY}.one_liner.validations.${errors.oneLiner.type}`} />
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCloseHandler} color={(Button as any).COLORS.LIGHT_GREY}>
                    <I18n k={`${TRANSLATION_KEY}.cancel`} />
                </Button>
                <Button className="m-l-12" onClick={onSubmitBI}>
                    <I18n k={`${TRANSLATION_KEY}.update`} />
                </Button>
            </Modal.Footer>
        </form>
    );
};

export const DisplayNameModal = ({
    isOpen = false,
    displayName,
    oneLiner,
    username,
    fullName,
    onClose = noop,
}: DisplayNameModalProps) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Layout className={styles.layout}>
            <ModalContent
                onClose={onClose}
                username={username}
                displayName={displayName}
                oneLiner={oneLiner}
                fullName={fullName}
            />
        </Modal.Layout>
    </Modal>
);

function selectSeller(seller: FederatedSeller) {
    return {
        sellerName: seller.user.name,
    };
}
