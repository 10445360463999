import { Providers } from './monitoring-constants';
import { bigQuery, BQPageViewOptions } from './big-query';
import { mixpanel, MPPageViewOptions } from './mixpanel';
import { grafana, GrafanaOptions } from './grafana';
import { DefaultData } from './types';

export interface PageViewEventsMap {
    [Providers.BigQuery]: BQPageViewOptions;
    [Providers.Mixpanel]: MPPageViewOptions;
    [Providers.Grafana]: GrafanaOptions;
}

export const firePageViewEvents =
    (defaultData: DefaultData = {}) =>
    (eventsMap: Partial<PageViewEventsMap> = {}) => {
        if (eventsMap.bigQuery) {
            bigQuery.pageView(eventsMap.bigQuery, defaultData);
        }

        if (eventsMap.mixpanel) {
            mixpanel.pageView(eventsMap.mixpanel, defaultData);
        }

        if (eventsMap.grafana) {
            grafana.count(eventsMap.grafana);
        }
    };
