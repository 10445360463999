import { getContext } from '@fiverr-private/fiverr_context';
import { FederatedSeller } from '../../types';
import { useSellerData } from '../useSellerData';

export const useIsSelfView = () => {
    const { sellerId } = useSellerData(selectSellerSlice);
    const { userId, queryParameters } = getContext();
    const selfViewParam = queryParameters?.up_rollout === 'true';

    return `${userId}` === `${sellerId}` && selfViewParam;
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        sellerId: seller.user.id,
    };
}
