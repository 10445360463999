import { getContext } from '@fiverr-private/fiverr_context';
import { FederatedSeller } from '../../types';
import { useSellerData } from '../useSellerData';

export const useIsSelfViewFreelancer = () => {
    const { sellerId } = useSellerData(selectSellerSlice);
    const { userId } = getContext();

    return `${userId}` === `${sellerId}`;
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        sellerId: seller.user.id,
    };
}
