import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { logger } from '@fiverr-private/obs';
import { List } from '@fiverr-private/list';
import { atoms, Box } from '@fiverr-private/theme';
import { Text } from '@fiverr-private/typography';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { useMonitoring } from '../../utils/monitoring';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import { invokeSkillsList, SkillFilterType } from './utils';
import { useCollapsibleSkills } from './hooks/useCollapsibleSkills';
import { SkillItem } from './SkillItem';

const T_PREFIX = 'seller_pages_toolbox.expert_in';

const onRenderError = (originalError) => {
    const err = new Error(`Failed while rendering the Expertise Component`);
    logger.error(err, {
        cause: originalError,
    });
};

interface ExpertiseProps {
    className?: string;
    withClamp?: boolean;
    filterType?: SkillFilterType;
    title?: React.ReactNode;
    shouldLinkSkills?: boolean;
}

export const Expertise = ({
    title = <I18n k={`${T_PREFIX}.title`} />,
    className,
    withClamp = true,
    filterType = 'none',
    shouldLinkSkills = true,
}: ExpertiseProps) => {
    const { Providers, fireEvents } = useMonitoring();
    const { gigCategories, proSubCategories, activeStructuredSkills } = useSellerData(selectSellerSlice);

    const onClickMoreBI = () => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'view + more expertise full list',
            },
        });
    };

    const items = useMemo(
        () =>
            invokeSkillsList({
                proSubCategories,
                gigCategories,
                skills: activeStructuredSkills,
                filter: filterType,
            }),
        [proSubCategories, gigCategories, activeStructuredSkills, filterType]
    );

    const { isShowingMore, showCollapsedSkills, visibleSkills, collapsedSkills } = useCollapsibleSkills({
        withClamp,
        skills: items,
    });

    if (isEmpty(items)) {
        return null;
    }

    return (
        <ErrorBoundary onError={onRenderError}>
            <Text marginBottom="2" fontWeight="semibold" color="bodyPrimary">
                {title}
            </Text>
            <List
                gap="2"
                aria-label="Skills List"
                paddingLeft="0"
                className={classNames(
                    className,
                    atoms({
                        display: 'flex',
                        flexWrap: 'wrap',
                    })
                )}
            >
                {visibleSkills.map((skill, index) => (
                    <SkillItem
                        key={`${skill.text}_${index}`}
                        skillText={skill.text}
                        skillUrl={skill.url}
                        shouldLink={shouldLinkSkills}
                    />
                ))}
                {!isEmpty(collapsedSkills) &&
                    (isShowingMore ? (
                        collapsedSkills.map((skill) => (
                            <SkillItem
                                key={skill.text}
                                skillText={skill.text}
                                skillUrl={skill.url}
                                shouldLink={shouldLinkSkills}
                            />
                        ))
                    ) : (
                        <>
                            <Box
                                as="button"
                                onClick={() => {
                                    showCollapsedSkills();
                                    onClickMoreBI();
                                }}
                                cursor="pointer"
                            >
                                <Text
                                    fontWeight="semibold"
                                    color="bodyPrimary"
                                    decoration="underline"
                                >{`+${collapsedSkills.length}`}</Text>
                            </Box>
                        </>
                    ))}
            </List>
        </ErrorBoundary>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        gigCategories: seller.gigCategories,
        proSubCategories: seller.proSubCategories,
        activeStructuredSkills: seller.activeStructuredSkills,
    };
}
