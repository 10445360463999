import { FederatedSeller } from '../types';
import { useIsSelfView } from './useIsSelfView';
import { useSellerData } from './useSellerData';

export const usePortfolio = () => {
    const isSelfView = useIsSelfView();
    const { portfolios } = useSellerData(selectSellerSlice);

    const totalCount = portfolios?.totalCount;

    const hasPortfolio = !!totalCount;
    const showPortfolio = isSelfView ? true : hasPortfolio;

    return {
        hasPortfolio,
        showPortfolio,
    };
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        portfolios: seller.portfolios,
    };
}
