import { getWindow } from './getGlobals';

export const TabName = {
    About: 'About',
    WorkExperience: 'WorkExperience',
    Services: 'Services',
    Portfolio: 'Portfolio',
    Reviews: 'Reviews',
    Testimonials: 'Testimonials',
};

export const scrollToTab = (tabId: string, offset = 0) => {
    const targetId = document.getElementById(tabId);
    if (targetId) {
        const { top } = targetId.getBoundingClientRect();
        const scrollTo = top + offset;
        const window = getWindow();

        if (window) {
            window.scrollBy({ top: scrollTo, behavior: 'smooth' });
        }
    }
};
