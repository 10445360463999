import React from 'react';
import { useHydrateAtoms } from 'jotai/utils';
import { Provider } from 'jotai';

export const AtomsProvider = ({ children, atoms }: { children: any; atoms: any[] }) => (
    <Provider>
        <HydratePropsToAtoms atoms={atoms}>{children}</HydratePropsToAtoms>
    </Provider>
);

const HydratePropsToAtoms = ({ atoms, children }: { atoms: any; children: any }) => {
    useHydrateAtoms(atoms);

    return children;
};
