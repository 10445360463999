import { chain, identity } from 'lodash';
import { QueryParameters } from '@fiverr-private/fiverr_context';
import pathfinder from '@fiverr-private/pathfinder';
import { SellerStructuredSkillLevel } from '../../gql-operations/gql-generated';
import { FederatedSeller } from '../../types';
import { getCategoryKey } from '../../utils/getCategoryKey';
import { getI18nText } from '../../utils/getI18nText';
import { getExpertiseKey, getSkillAlias } from '../../utils/getSkillKey';
import { UrlContext, UrlSkill } from '../../utils/UrlContext';

type SellerCategory = FederatedSeller['gigCategories'][0] | FederatedSeller['proSubCategories'][0];
type SellerSkill = FederatedSeller['activeStructuredSkills'][0];

export type SkillFilterType = 'none' | 'only_skills';

export type ContextualParameters = QueryParameters & {
    expertises: string;
    gigs: string;
    is_choice?: 'true' | 'false';
    ref?: string; // package_includes:logo_transparency,social_media_kit|gig_price_range:500,50000
    imp_id: string;
    ref_ctx_id: string;
    source: string;
    referrer_gig_slug?: string;
};

export interface SkillTag {
    text: string;
    url?: string;
}

// README: It works only for ENG. Should be slug, but structured_skills does not have it yet
const getSkillUrl = (skillName: string) =>
    pathfinder('skill', {
        skill: skillName.replace(/\s+/g, '-').replace(/\./g, '').toLowerCase(),
    });

export const getCategoriesText = (categories: SellerCategory[] = []) =>
    categories.map(({ categoryId, subCategoryId, nestedCategoryId }) =>
        getI18nText(getCategoryKey({ categoryId, subCategoryId, nestedCategoryId }))
    );

const getCategoriesSkills = (categories: SellerCategory[] = []): (SkillTag | null)[] => {
    const categoryTexts = getCategoriesText(categories).filter(identity) as string[];
    return categoryTexts.map((text) => ({ text, url: getSkillUrl(text) }));
};

const getValuableSkillsText = (skills: SellerSkill[] = []): (SkillTag | null)[] =>
    skills
        .filter((item) => item.level !== SellerStructuredSkillLevel.Beginner)
        .map((skill) => {
            const text = getI18nText(getSkillAlias(skill));
            return text ? { text, url: getSkillUrl(skill.name) } : null;
        });

const getExpertiseText = (skills: UrlSkill[] = []) =>
    skills.map((skill) => getI18nText(getExpertiseKey(skill))).filter(identity) as string[];

export const invokeSkillsList = ({
    proSubCategories,
    gigCategories,
    skills,
    filter = 'none',
}: {
    proSubCategories: SellerCategory[];
    gigCategories: SellerCategory[];
    skills: SellerSkill[];
    filter?: SkillFilterType;
}) => {
    const urlSkills = UrlContext.getUrlSkills();
    const contextSkills: SkillTag[] = getExpertiseText(urlSkills).map((skill) => ({
        text: skill,
        url: getSkillUrl(skill),
    }));

    let items: (SkillTag | null)[];

    switch (filter) {
        case 'only_skills':
            const categoriesOnlyItems = chain([] as typeof items)
                .concat(getCategoriesSkills(proSubCategories))
                .filter((value) => !!value)
                .map((value) => value?.text.toLowerCase())
                .uniqBy((value) => value?.toLowerCase())
                .value();

            items = chain([] as typeof items)
                .concat(contextSkills)
                .concat(getValuableSkillsText(skills))
                .filter((value) => !!value && !categoriesOnlyItems.includes(value?.text.toLowerCase() as string))
                .uniqBy((value) => value?.text.toLowerCase())
                .value();
            break;
        default:
            items = chain([] as typeof items)
                .concat(contextSkills)
                .concat(getCategoriesSkills(proSubCategories))
                .concat(getCategoriesSkills(gigCategories))
                .concat(getValuableSkillsText(skills))
                .filter((value) => !!value)
                .uniqBy((value) => value?.text.toLowerCase())
                .value();
    }

    return items as SkillTag[];
};
