import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

interface ButtonsGroupProps {
    children: React.ReactNode;
    className?: string;
}

export const ButtonsGroup = ({ className, children }: ButtonsGroupProps) => (
    <div className={classnames(className, styles.buttonsGroup, 'flex flex-center')}>{children}</div>
);
