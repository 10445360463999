import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { StarBubbleIcon } from '@fiverr-private/icons';
import { Stack } from '@fiverr-private/layout_components';
import { Tooltip } from '@fiverr-private/tooltip';
import { Text } from '@fiverr-private/typography';
import { theme } from '@fiverr-private/theme';
import { TabName, scrollToTab } from '../../../utils/tab-names';

interface TestimonialsBadgeProps {
    testimonialsCount: number;
}

export const TestimonialsBadge: React.FC<TestimonialsBadgeProps> = ({ testimonialsCount }) => (
    <Tooltip content={<I18n k="seller_pages_toolbox.testimonials.tooltip" />}>
        <Stack alignItems="center" gap="1" cursor="pointer" onClick={() => scrollToTab(TabName.Testimonials)}>
            <StarBubbleIcon size={20} color={theme.colors.grey_1200} />
            <Text color="bodySecondary" size="b_md" as="span">
                (
                <Text color="bodySecondary" decoration="underline" as="span">
                    {testimonialsCount}
                </Text>
                )
            </Text>
        </Stack>
    </Tooltip>
);
