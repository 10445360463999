import { noop } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { getLinkedInData, importLinkedInData } from '../apis/workExperience';
import { LinkedInData } from '../components/WorkExperience/shared/types';

export const usePrePopulateLinkedinUrl = ({ onSuccess = noop, onError = noop, enabled = true }) =>
    useQuery<LinkedInData>('prePopulateLinkedInUrl', () => getLinkedInData(), {
        onSuccess,
        onError,
        staleTime: Infinity,
        enabled,
    });

export const useImportLinkedInData = ({ onSuccess = noop, onError = noop }) =>
    useMutation((profileUrl: string) => importLinkedInData(profileUrl), {
        onSuccess,
        onError,
    });
