import shallowEqual from 'shallowequal';
import { useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { sellerAtom } from '../../atoms';
import { FederatedSeller } from '../../types';

export const useSellerData = <T>(selectSellerSlice: (seller: FederatedSeller) => T): T => {
    const value = useAtomValue(selectAtom(sellerAtom, selectSellerSlice, shallowEqual));
    return value;
};
