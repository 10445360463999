import { useEffect, useState } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { FederatedSeller } from '../types';
import { TabName } from '../utils/tab-names';
import { useSellerData } from './useSellerData';
import { useIsSelfViewFreelancer } from './useIsSelfViewFreelancer';
import { usePrePopulateLinkedinUrl } from './useImportWorkExperience';

export const useWorkExperience = (viewOnly?: boolean) => {
    const isSelfView = useIsSelfViewFreelancer();
    const { workExperiences, isPro } = useSellerData(selectSellerSlice);
    const [isDrawerOpenByDefault, setIsDrawerOpenByDefault] = useState(false);
    const { queryParameters } = getContext();

    if (!isPro) {
        return {
            hasWorkExperience: false,
            showWorkExperience: false,
            isDrawerOpenByDefault: false,
        };
    }

    const { source, tabId } = queryParameters;
    const hasWorkExperience = workExperiences && workExperiences.length > 0;
    const showWorkExperience = hasWorkExperience || (isSelfView && !viewOnly);

    useEffect(() => {
        if (
            source === 'seller_billboard' &&
            tabId === TabName.WorkExperience &&
            showWorkExperience &&
            isSelfView &&
            !hasWorkExperience
        ) {
            setIsDrawerOpenByDefault(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source, tabId]);

    usePrePopulateLinkedinUrl({
        enabled: !!(isPro && showWorkExperience && !hasWorkExperience),
    });

    return {
        hasWorkExperience,
        showWorkExperience,
        workExperiences,
        isDrawerOpenByDefault,
    };
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        workExperiences: seller.workExperiences.nodes,
        isPro: seller.isPro || false,
    };
}
