import React from 'react';
import classNames from 'classnames';
import pathfinder from '@fiverr-private/pathfinder';
import { VideoIcon } from '@fiverr-private/icons';
import { Consultation } from '@fiverr-private/consultation';
import { Button, Icon } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { grey_1200 } from '@fiverr-private/sass/helpers';
import { useCurrentPageName } from '../../hooks/useCurrentPageName';
import { useLocalizationContext } from '../../hooks/LocalizationContext';
import { useSellerData } from '../../hooks/useSellerData';
import { useConsultationData } from '../../hooks/useConsultationData';
import { FederatedSeller } from '../../types';
import { ConsultationButtonProps } from './types';
import styles from './index.module.scss';

export const ConsultationButton: React.FC<ConsultationButtonProps> = ({ className, buttonClassName }) => {
    const { userId } = getContext();
    const pageName = useCurrentPageName();
    const { currency } = useLocalizationContext();
    const { sellerId: id, sellerName, profileImageUrl } = useSellerData(selectSellerSlice);
    const consultationData = useConsultationData();

    const sellerId = Number(id);

    const biEnrichment = {
        pageName,
        elementName: 'consultation_button',
        group: pageName,
        seller: { id: sellerId },
        user: { id: userId },
    };

    const consultationMixpanelEnrichment = {
        'Seller Name': sellerName,
        Origin: pageName,
        'Page Name': pageName,
    };

    const learnMoreLink = pathfinder(
        'help_center_article',
        { article_id: 12744510059665 },
        { query: { segment: 'buyer' } }
    );

    return (
        <Consultation
            consultationData={consultationData}
            sellerId={sellerId}
            sellerName={sellerName}
            profilePhoto={profileImageUrl}
            biEnrichment={biEnrichment}
            mixpanelEnrichment={consultationMixpanelEnrichment}
            learnMoreLink={learnMoreLink}
            currency={currency}
            className={classNames(styles.consultationContainer, className)}
        >
            <Button fill="ghost" className={classNames(styles.consultationBtn, buttonClassName)} color="light-grey">
                <>
                    <Icon className={styles.icon} size={18} color={grey_1200}>
                        <VideoIcon />
                    </Icon>
                    <I18n k="seller_pages_toolbox.consultation.button_cta" />
                </>
            </Button>
        </Consultation>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        sellerId: seller.user.id,
        sellerName: seller.user.name,
        profileImageUrl: seller.user.profileImageUrl,
    };
}
