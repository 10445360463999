import { getWindow } from '@fiverr-private/seller_pages_toolbox';

export const TabName = {
    About: 'About',
    Services: 'Services',
    Portfolio: 'Portfolio',
    WorkExperience: 'WorkExperience',
    Reviews: 'Reviews',
    Testimonials: 'Testimonials',
} as const;

type TabNameType = (typeof TabName)[keyof typeof TabName];

interface TabPayload {
    roleId?: string;
    tabId: TabNameType;
    offset?: number;
}

interface CreateTabIdPayload {
    roleId?: string;
    tabId: TabNameType;
}

export const createTabId = ({ roleId, tabId }: CreateTabIdPayload) => (roleId ? `${roleId}-${tabId}` : tabId);

export const scrollToTab = ({ roleId, tabId, offset = 0 }: TabPayload) => {
    const targetId = document.getElementById(createTabId({ roleId, tabId }));

    if (targetId) {
        const { top } = targetId.getBoundingClientRect();
        const scrollTo = top + offset;
        const win = getWindow();
        if (win) {
            win.scrollBy({ top: scrollTo, behavior: 'smooth' });
        }
    }
};
