import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { grey_900 } from '@fiverr-private/sass/helpers/index';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import { getLanguageCodeLevelKey } from './utils';

export const SpokenLanguages = () => {
    const { languages } = useSellerData(selectSellerSlice);

    return (
        <div>
            <div className="text-semi-bold tbody-5 m-b-16 co-grey-1200">
                <I18n k={'seller_pages_toolbox.details.i_speak'} />
            </div>
            <div>
                {languages.map(({ code, level }) => (
                    <div className="m-b-16" key={code}>
                        <span className="co-grey-1200">
                            <I18n k={`languages.codes.${code.toLowerCase()}`} />
                        </span>
                        <span className="m-l-16" style={{ color: grey_900 }}>
                            <I18n k={getLanguageCodeLevelKey(level)} />
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        languages: seller.user.languages,
    };
}
