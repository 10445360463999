import React from 'react';
import classnames from 'classnames';
import { useAtom } from 'jotai/index';
import PropTypes from 'prop-types';
import {
    AboutMe,
    ButtonsGroup,
    Certifications,
    ContactButton,
    Courses,
    Educations,
    Expertise,
    LearnMoreModalHeader,
    NotableClients,
    ProOverview,
    SellerPageFederatedSeller,
    SPACING_MODES,
    useSellerData,
} from '@fiverr-private/seller_pages_toolbox';
import { Col, Grid } from '@fiverr-private/fit';
import { ROLLOUTS } from '../../../../server/middlewares/addRolloutsData/constants';
import { rolloutsAtom } from '../../../atoms';
import { useShouldRenderSaveSellerButton } from '../../../hooks/useShouldRenderSaveSellerButton';
import { SaveSellerButton } from '../../SaveSellerButton';
import { SellerProfile } from '../../SellerProfile';
import { TraderMoreInfoTrigger } from '../../TraderMoreInfoTrigger';
import { useShouldRenderTraderDetailsButton } from '../../../hooks/useShouldRenderTraderDetailsButton';
import { useContactSellerModalUpdater } from '../../ContactSellerModal/hooks/useContactSellerModal';
import classes from './index.module.scss';

interface LearnMoreContentProps {
    onRatingsClick?(close?: void): any;
    close?(): void;
}

export const LearnMoreContent: React.FC<LearnMoreContentProps> = ({ close, onRatingsClick }) => {
    const setShowModal = useContactSellerModalUpdater();
    const { courses, activeEducations, certifications, isPro, sellerId, sellerName } = useSellerData(selectSellerSlice);
    const shouldRenderSaveSellerButton = useShouldRenderSaveSellerButton();
    const shouldRenderTraderDetailsButton = useShouldRenderTraderDetailsButton();
    const [rollouts] = useAtom(rolloutsAtom);
    const inQualasNewLevelSystemRollout = rollouts[ROLLOUTS.QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION];

    const onContactOpen = () => {
        setShowModal(true);
    };

    return (
        <>
            <div className={classnames(classes.headerWrapper, 'flex', 'flex-between', 'flex-items-start')}>
                <LearnMoreModalHeader
                    className={classnames(classes.header, 'flex')}
                    onRatingsClick={onRatingsClick?.(close as void)}
                    showSellerLevelNewBadges={inQualasNewLevelSystemRollout}
                />
                <div className={classes.infoButtons}>
                    <ButtonsGroup className="flex-end">
                        <ContactButton onContactOpen={onContactOpen} className={classes.buttonContact} />
                        {shouldRenderSaveSellerButton && <SaveSellerButton />}
                    </ButtonsGroup>
                </div>
            </div>
            <Grid theme={(Grid as any).THEMES.TWELVE}>
                <Col md={7} sm={12}>
                    {isPro && <ProOverview spacingMode={SPACING_MODES.LARGE} />}
                    <AboutMe withClamp={false} className="m-b-24" />
                    <Expertise withClamp={false} />
                    <NotableClients className={classes.notableClients} wrapTitle />
                    {!!courses?.nodes?.length && <Courses />}
                    {!!activeEducations.length && <Educations />}
                    {!!certifications.length && <Certifications />}
                    {shouldRenderTraderDetailsButton && (
                        <TraderMoreInfoTrigger
                            className={classes.traderInfoWrapper}
                            sellerId={sellerId}
                            sellerName={sellerName}
                        />
                    )}
                </Col>
                <Col md={4} mdOffset={1} sm={12} xsOffset={0}>
                    <SellerProfile />
                </Col>
            </Grid>
        </>
    );
};

LearnMoreContent.propTypes = {
    close: PropTypes.func,
    onRatingsClick: PropTypes.func,
};

function selectSellerSlice(seller: SellerPageFederatedSeller) {
    return {
        sellerId: seller.user.id,
        sellerName: seller.user.name,
        courses: seller.courses,
        activeEducations: seller.activeEducations,
        certifications: seller.certifications,
        isPro: seller.isPro,
    };
}
