import React from 'react';
import { LazyComponent } from '../LazyComponent/LazyComponent';

const lazyImport = () => import(/* webpackChunkName: 'UserActivationModal' */ '@fiverr-private/user_activation');

const UserActivationModalLazy = (props) => (
    <LazyComponent lazyImport={lazyImport} componentProps={props} moduleName="UserActivationModal" />
);

export default UserActivationModalLazy;
