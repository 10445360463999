import React, { useEffect } from 'react';
import { Divider } from '@fiverr-private/layout_components';
import { TryHourlyRatesSection } from '@fiverr-private/business_entry_point';
import { SellerPageFederatedSeller, useSellerData } from '@fiverr-private/seller_pages_toolbox';
import { reportBqClickEvent, reportBqImpressionEvent, FreelancerPageType } from './HourlyRatesSection.events';

interface TryHourlyRatesSectionProps
    extends Pick<React.ComponentProps<typeof TryHourlyRatesSection>, 'titleColor' | 'titleSize' | 'titleFontWeight'> {
    className?: string;
    withDivider?: 'top' | 'bottom';
    freelancer_page_type: FreelancerPageType;
}

export const HourlyRatesSection: React.FC<TryHourlyRatesSectionProps> = ({
    className,
    freelancer_page_type,
    withDivider,
    titleSize,
    titleColor,
    titleFontWeight,
}) => {
    const { sellerName, hasHourlyRate } = useSellerData(selectSellerSlice);

    useEffect(() => {
        if (hasHourlyRate) {
            reportBqImpressionEvent({ freelancer_page_type });
        }
    }, [hasHourlyRate, freelancer_page_type]);

    if (!hasHourlyRate) {
        return null;
    }

    const onClick = () => reportBqClickEvent({ freelancer_page_type });

    return (
        <>
            {withDivider === 'top' && <Divider />}
            <TryHourlyRatesSection
                className={className}
                sellerName={sellerName}
                source="hourly_freelancer_page"
                registrationSource="hourly_freelancer_page"
                migrationBiEnrichment={{ pageElementName: 'hourly_upgrade' }}
                titleSize={titleSize}
                titleColor={titleColor}
                titleFontWeight={titleFontWeight}
                descriptionColor="grey_1100"
                onClick={onClick}
                onMigrationSuccess={() => window.location.reload()}
            />
            {withDivider === 'bottom' && <Divider />}
        </>
    );
};

function selectSellerSlice(seller: SellerPageFederatedSeller) {
    return {
        sellerName: seller.user.profile.displayName || seller.user.name,
        hasHourlyRate: !!seller.hourlyRate,
    };
}
