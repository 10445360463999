import React from 'react';
import { Avatar, AvatarIcon, AvatarImage } from '@fiverr-private/avatar';
import { Icon } from '@fiverr-private/fit';
import { CheckStarSolidIcon } from '@fiverr-private/icons';
import { business_800, white } from '@fiverr-private/sass/helpers';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import { ProAvatarProps } from './types';

export const ProAvatar = ({ avatarSize = 'xl', iconSize = 16, iconPadding = 4 }: ProAvatarProps) => {
    const { username, profileImageUrl } = useSellerData(selectSellerSlice);

    if (!profileImageUrl) {
        return null;
    }

    return (
        <Avatar size={avatarSize} username={username} showRing={false}>
            <AvatarImage src={profileImageUrl} alt={username} />
            <AvatarIcon padding={iconPadding} backgroundColor={business_800} position="bottom-right" showRing={false}>
                <Icon size={iconSize}>
                    <CheckStarSolidIcon color={white} />
                </Icon>
            </AvatarIcon>
        </Avatar>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        username: seller.user.name,
        profileImageUrl: seller.user.profileImageUrl,
    };
}
