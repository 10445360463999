import { snakeCase, isArray } from 'lodash';
import { stats, MetricValue } from '@fiverr-private/obs';

export interface GrafanaOptions {
    app: string;
    feature: string;
    metric: string | string[];
    [key: string]: any;
}

const preparePrefix = ({ app, feature }: Pick<GrafanaOptions, 'app' | 'feature'>) =>
    `${snakeCase(app)}.${snakeCase(feature)}`.toLowerCase();

const prepareMetric = (metric: GrafanaOptions['metric']) =>
    isArray(metric) ? metric.map(snakeCase).join('.') : snakeCase(metric);

export const grafana = {
    count: ({ count = 1, metric, ...prefix }: GrafanaOptions & { count?: number }) => {
        stats.count(preparePrefix(prefix), prepareMetric(metric), count);
    },

    time: ({ start, metric, ...prefix }: GrafanaOptions & { start?: MetricValue }) => {
        stats.time(preparePrefix(prefix), prepareMetric(metric), start);
    },
};
