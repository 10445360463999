import pathfinder from '@fiverr-private/pathfinder';
import safeRequest from '../safeRequest';
import { LinkedInData, WorkExperienceUpsertRequest } from '../../components/WorkExperience/shared/types';

const getUpsertUrlPath = () => pathfinder('sellers_work_experiences');
const getCompaniesAutocompleteUrlPath = () => pathfinder('notable_clients_companies_autocomplete');
const getSkillsAutocompleteUrlPath = () => pathfinder('seller_profile_autocomplete_skill');
export const getLinkedInDataPath = () => pathfinder('sellers_work_experiences_get_linkedin_data');
export const getImportLinkedInDataPath = () => pathfinder('sellers_work_experiences_import_linkedin_data');

export const upsertWorkExperiences = ({ workExperiences }: WorkExperienceUpsertRequest) =>
    safeRequest(
        'PUT',
        getUpsertUrlPath(),
        'upsert_work_experiences',
        { data: { workExperiences: workExperiences || [] } },
        5000
    );

export const companiesAutocomplete = (query: string) =>
    safeRequest('GET', getCompaniesAutocompleteUrlPath(), 'companies_autocomplete', {
        params: {
            name: query,
            enrich: true,
        },
    });

export const skillsAutocomplete = (query: string) =>
    safeRequest('GET', getSkillsAutocompleteUrlPath(), 'skills_autocomplete', {
        params: {
            query,
        },
    });

export const getLinkedInData: () => Promise<LinkedInData> = () =>
    safeRequest('GET', getLinkedInDataPath(), 'get_linkedin_data');

export const importLinkedInData = (profileUrl: string) =>
    safeRequest('POST', getImportLinkedInDataPath(), 'trigger_import_linkedin_data', {
        data: {
            profileUrl,
        },
    });
