export enum Providers {
    BigQuery = 'bigQuery',
    Mixpanel = 'mixpanel',
    Grafana = 'grafana',
}

export enum SOURCES {
    page = 'seller_page',
    modal = 'about_seller_modal',
    stickyHeader = 'sticky_header',
    header = 'header',
}
