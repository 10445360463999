import { useState } from 'react';
import { SkillTag } from '../utils';

const APPROX_SKILLS_TO_SHOW = 5;
const getInitialSkillsToShow = (total) => (APPROX_SKILLS_TO_SHOW > total ? total : APPROX_SKILLS_TO_SHOW);

export const useCollapsibleSkills = ({ skills, withClamp }: { skills: SkillTag[]; withClamp: boolean }) => {
    const [isShowingMore, setShowingMore] = useState(!withClamp);

    const visibleSkills = skills.slice(0, getInitialSkillsToShow(skills.length));
    const collapsedSkills = skills.slice(getInitialSkillsToShow(skills.length));

    return {
        isShowingMore,
        visibleSkills,
        collapsedSkills,
        showCollapsedSkills: () => setShowingMore(true),
        showLessCollapsedSkills: () => setShowingMore(false),
    };
};
